<!--
 * @Author: Yran
 * @Date: 2022-01-13 13:40:26
 * @LastEditors: Yran
 * @LastEditTime: 2022-01-20 09:54:42
-->
<template>
  <a-modal :width="750" @cancel="$emit('update:visible')" :footer="null" class="denied-trial-modal" :visible="visible" :mask-closable="false" centered title="素材审核意见-素材库">
    <a-tabs v-model:activeKey="activeKey" @change="getTableData">
      <a-tab-pane v-for="pane in tabList" :key="pane.key" :tab="pane.tab">
        <a-table size="small" :scroll="{ y: 300, x: '100%' }" :pagination="tableModalPagination" :data-source="tableData" :columns="tableColumns" row-key="id" :loading="tableLoading" @change="tableModalChange" bordered> </a-table>
      </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>
<script>
import { columnMap } from '@/utils/utils.js';
import { getMaterialReviewDetail } from '@/api/material/material.js';
export default {
  name: 'DeniedTrialModal',
  emits: ['update:visible'],
  props: {
    visible: { type: Boolean, default: false },
    materialId: { type: Number, default: null },
  },
  data() {
    return {
      tabList: [
        { tab: '巨量引擎', key: 'TT' },
        { tab: '腾讯广告', key: 'GDT' },
        { tab: '磁力引擎', key: 'KS' },
        { tab: '巨量千川', key: 'QC' },
      ],
      activeKey: 'TT',
      tableData: [],
      tableColumns: [
        { title: '拒审理由', dataIndex: 'message', key: 'message' },
        { title: '广告', dataIndex: 'creativeName', key: 'creativeName' },
        { title: '广告ID', dataIndex: 'creativeId', key: 'creativeId' },
      ],
      tableLoading: false,
      tableModalPagination: { page: 1, pageSize: 10, showTotal: total => `总计${total}条数据`, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50'] },
    };
  },
  watch: {
    visible: {
      handler(newVal) {
        if (newVal) {
          this.getTableData();
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * @description: 表格的page改变、排序改变
     * @param {Object} pagination 分页数据
     * @return {*}
     */
    tableModalChange(pagination) {
      this.tableModalPagination.page = pagination.current;
      this.tableModalPagination.pageSize = pagination.pageSize;
      this.getTableData();
    },
    /**
     * @description: 获取拒审理由表格
     * @param {*}
     * @return {*}
     */
    async getTableData() {
      this.tableLoading = true;
      const { pageSize: limit, page } = this.tableModalPagination;
      const params = { id: this.materialId, channel: this.activeKey, limit, page };
      let res = await getMaterialReviewDetail(params);
      if (res?.code == 0) {
        this.tableData = res.page?.list || [];
        this.tableModalPagination.total = res.page?.totalCount;
        this.tableModalPagination.current = res.page?.currPage;
        this.tableModalPagination.pageSize = res.page?.pageSize;
      }
      this.tableLoading = false;
    },
  },
};
</script>
<style lang='less'>
.denied-trial-modal {
  .ant-modal-body {
    padding: 0 12px 12px;
  }
}
</style>