<!--
 * @Author: LiuXin
 * @Date: 2021-09-09 14:05:40
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-10-21 16:55:25
-->
<template>
  <a-modal :visible="visible" @cancel="handleCancel" @ok="handelOk" title="月度目标" :width="600" :mask-closable="false" class="month_targrt_modal">
    <a-form v-bind="col" class="month_target_form">
      <a-form-item label="部门" class="title_item"><PlusCircleOutlined @click="addFormItem('dept')" class="blue-icon" /></a-form-item>
      <template v-for="(dept, index) in formData.dept" :key="dept.deptId">
        <a-form-item>
          <a-row :gutter="10">
            <a-col :span="10" :offset="2">
              <a-select v-bind="$filterOptions" v-model:value="dept.deptId" placeholder="请选择部门">
                <a-select-option :disabled="selected.dept.has(item.deptId)" @click="dept.deptName = item.name" v-for="item in deptList" :key="item.deptId" :label="item.name">{{ item.name }}</a-select-option>
              </a-select>
            </a-col>
            <a-col :span="10"> <a-input v-model:value="dept.target" placeholder="请输入目标金额" oninput="value=value.toString().match(/^\d+(?:\.\d{0,2})?/)"></a-input></a-col>
            <a-col :span="2"><span class="mr5">万</span> <DeleteOutlined @click="deleteItem('dept', index, dept)" v-if="formData.dept.length > 0" /> </a-col>
          </a-row>
        </a-form-item>
      </template>

      <a-form-item label="运营" class="title_item"><PlusCircleOutlined @click="addFormItem('operator')" class="blue-icon" /></a-form-item>
      <template v-for="(operator, index) in formData.operator" :key="operator.userId">
        <a-form-item>
          <a-row :gutter="10">
            <a-col :span="10" :offset="2">
              <a-select v-bind="$filterOptions" v-model:value="operator.userId" placeholder="请选择运营人员">
                <a-select-option :disabled="selected.operator.has(item.userId)" @click="operator.userName = item.username" v-for="item in operatorList" :key="item.userId" :label="item.username">{{ item.username }}</a-select-option>
              </a-select>
            </a-col>
            <a-col :span="10"> <a-input v-model:value="operator.target" placeholder="请输入目标金额" oninput="value=value.toString().match(/^\d+(?:\.\d{0,2})?/)"></a-input></a-col>
            <a-col :span="2"><span class="mr5">万</span> <DeleteOutlined @click="deleteItem('operator', index, operator)" v-if="formData.operator.length > 0" /> </a-col>
          </a-row>
        </a-form-item>
      </template>

      <!-- <a-form-item label="产品"><PlusCircleOutlined @click="addFormItem('product')" class="blue-icon" /></a-form-item>
      <template v-for="(product, index) in formData.product" :key="product.productCode">
        <a-form-item>
          <a-row :gutter="10">
            <a-col :span="10" :offset="2">
              <a-select v-bind="$filterOptions" v-model:value="product.productCode" placeholder="请选择产品">
                <a-select-option :disabled="selected.product.has(item.projectCode)" @click="product.productName = item.projectName" v-for="item in projectList" :key="item.projectCode" :label="item.projectName">{{ item.projectName }}</a-select-option>
              </a-select>
            </a-col>
            <a-col :span="10"> <a-input v-model:value="product.target" placeholder="请输入目标金额"></a-input></a-col>
            <a-col :span="4"><span class="mr5">万</span> <DeleteOutlined @click="deleteItem('product', index,product)" v-if="formData.product.length > 0" /> </a-col>
          </a-row>
        </a-form-item>
      </template> -->
    </a-form>
  </a-modal>
</template>

<script>
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import { getMonthTargrtDrop, getMonthTargrt, deleteMonthTargrt, addMonthTargrt, updateMonthTargrt } from '@/api/dashboard';
import { Modal } from 'ant-design-vue';

export default {
  name: 'MonthTargetModal',
  components: { PlusCircleOutlined, DeleteOutlined },
  emits: ['update:visible', 'afterSave'],
  props: {
    visible: { type: Boolean, default: false },
  },
  data() {
    let { deptList, operatorList, projectList } = this.$store.state.dorpDownData;
    return {
      col: { labelCol: { span: 2 }, wrapperCol: { span: 22 } },
      deptList: deptList, //下拉部门列表
      operatorList: operatorList, //下拉运营列表
      projectList: projectList, //下拉产品列表
      selected: {}, //记录下拉框已经选择项
      emptyFormData: {
        //空表单原始数据，用以添加新表单项时使用
        product: [{ productCode: '', productName: '', target: '' }],
        operator: [{ userId: '', userName: '', target: '' }],
        dept: [{ deptId: '', deptName: '', target: '' }],
      },
      formData: { product: [], operator: [], dept: [] }, //表单数据
    };
  },
  watch: {
    // 用来监听已经选择项，使之不可再次选择
    formData: {
      handler() {
        this.selected = { product: new Set(), operator: new Set(), dept: new Set() };
        this.formData.dept &&
          this.formData.dept.forEach(item => {
            this.selected.dept.add(item.deptId);
          });
        this.formData.operator &&
          this.formData.operator.forEach(item => {
            this.selected.operator.add(item.userId);
          });
        this.formData.product &&
          this.formData.product.forEach(item => {
            if (item.productCode) {
              this.selected.product.add(item.productCode);
            }
          });
      },
      deep: true,
    },
    visible(val) {
      if (val) {
        this.getTarget();
      }
    },
  },
  async mounted() {
    const auths = localStorage.getItem('funcPoint');
  },
  methods: {
    // 获取月度目标
    async getTarget() {
      const res = await getMonthTargrt();
      this.formData = Object.assign({}, this.formData, res.data);
    },
    // 关闭弹框
    handleCancel() {
      this.$emit('update:visible', false);
    },
    // 点击确定
    async handelOk() {
      const addParams = { product: [], operator: [], dept: [] };
      const updateParams = { product: [], operator: [], dept: [] };
      // 有id的调用编辑接口，无id的调用添加接口
      Object.keys(addParams).forEach(key => {
        this.formData[key] &&
          this.formData[key].forEach(item => {
            let newItem = { ...item };
            newItem.target = parseFloat(newItem.target);
            delete newItem[0];
            if (newItem.id) {
              updateParams[key].push(newItem);
            } else {
              addParams[key].push(newItem);
            }
          });
        // 如果这一项没有要添加的就删除
        if (updateParams[key].length == 0) {
          delete updateParams[key];
        }
        if (addParams[key].length == 0) {
          delete addParams[key];
        }
      });
      // 调用接口
      if (Object.keys(addParams).length) {
        await add(addParams);
      }
      if (Object.keys(updateParams).length) {
        await update(updateParams);
      }
      this.$emit('afterSave');
      this.$message.success('保存成功');
      this.$emit('update:visible', false);

      async function add(params) {
        const res = await addMonthTargrt(params);
        if (res.code != 0) {
          throw '添加失败';
        }
        return res;
      }
      async function update(params) {
        const res = await updateMonthTargrt(params);
        if (res.code != 0) {
          throw '更新失败';
        }
        return res;
      }
    },
    // 添加表单项
    addFormItem(type) {
      const newItem = { ...this.emptyFormData[type][0] };
      this.formData[type].push(newItem);
    },
    // 删除表单项
    async deleteItem(type, index, data) {
      const param = { [type]: [data] };
      const _this = this;
      Modal.confirm({
        title: '删除',
        content: '该信息删除后将不予保留，请谨慎操作！',
        async onOk() {
          if (data.id) {
            const res = await deleteMonthTargrt(param);
            if (res.code === 0) {
              _this.$message.info('已删除');
              _this.formData[type].splice(index, 1);
            }
          } else {
            _this.formData[type].splice(index, 1);
          }
        },
        onCancel() {},
      });
    },
  },
};
</script>

<style scoped lang="less">
.month_target_form {
  height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  :deep(.ant-form-item) {
    &.title_item {
      height: 30px;
      margin-top: -10px;
      &:not(:first-of-type) {
        height: 28px;
      }
    }
    margin-bottom: 14px;
    .ant-input {
      height: 32px;
    }
    .ant-col-4 {
      line-height: 32px;
    }
  }
}
</style>
<style lang="less">
.month_targrt_modal {
  .ant-modal-body {
    padding: 20px 16px;
  }
}
</style>