<!--
 * @Author: chenxing
 * @Date: 2021-08-03 11:24:24
 * @LastEditors: chenxing
 * @LastEditTime: 2021-08-31 15:59:36
-->
<template>
  <a-modal :visible="visible" title="批量推送" :body-style="{ height: '19.875rem', padding: '0.35rem 0.45rem' }" width="24rem" :ok-button-props="{ loading: pushing }" @ok="onModalConfirm" @cancel="onModalCancel">
    <div style="margin-bottom: 0.35rem">
      <!-- <a-button type="primary" @click="quickSelectAccount">显示当前用户所有媒体账号</a-button> -->
      <!-- <a-tooltip title="用户绑定的该产品的所有账户ID">
        <QuestionCircleFilled style="margin-left: 0.2rem; color: #65a5e3; font-size: 0.5rem" />
      </a-tooltip> -->
    </div>
    <div class="flexAlignCenter">
      <a-spin :spinning="accountLoading" tip="获取账号中...">
        <div class="left">
          <div class="header">
            <div class="header-tab" :class="{ 'header-tab-disabled': enabledChannel != '' && enabledChannel != channel.value }" v-for="channel in channelList" :key="channel.value" @click="onTabChange(channel.value)">
              {{ channel.text }}
              <div :class="{ 'tab-indicator-show': selectedChannel == channel.value }" class="tab-indicator"></div>
            </div>
          </div>
          <div style="display: flex">
            <a-input class="search-input" placeholder="请输入关键字，按回车键搜索" allow-clear v-model:value="leftSearchKey" @pressEnter="onLeftSearchEnter" @change="onLeftSearchInputChange">
              <template #prefix>
                <SearchOutlined />
              </template>
            </a-input>
          </div>
          <a-list style="overflow-y: scroll; height: 11.7rem" item-layout="vertical" :split="false">
            <a-list-item v-for="company in mdaList" :key="company.companyName" class="list-item-level-1">
              <div style="display: flex; padding-bottom: 0.25rem">
                <a-checkbox v-model:checked="company.checkedAll" :indeterminate="company.indeterminate" @change="onCompanyChange(company.companyName)">{{ company.companyName }}</a-checkbox>
              </div>
              <a-list-item v-for="mda in company.advertisers" :key="mda.advertiserId" class="list-item-level-2">
                <a-checkbox v-model:checked="mda.checked" @change="onMdaChange(company.companyName, mda.advertiserId)">
                  <template v-if="selectedChannel == 'TT'"> {{ mda.advertiserName }}({{ mda.advertiserId }})</template>
                  <template v-else> {{ mda.advertiserId }}</template>
                </a-checkbox>
              </a-list-item>
            </a-list-item>
          </a-list>
        </div>
      </a-spin>
      <div class="right">
        <div class="header">
          <label>已选账户({{ getSelectNum }})</label>
          <a href="#" @click="clearAll">清空已选</a>
        </div>
        <div>
          <div style="display: flex">
            <a-input class="search-input" placeholder="请输入关键字，按回车键搜索" allow-clear v-model:value="rightSearchKey" @pressEnter="onRightSearchEnter" @change="onRightSearchInputChange">
              <template #prefix>
                <SearchOutlined />
              </template>
            </a-input>
          </div>
          <a-list style="overflow-y: scroll; height: 11.7rem" item-layout="vertical" :split="false">
            <a-list-item v-for="company in selectedMdaList" :key="company.companyName" class="list-item-level-1">
              <div style="display: flex; padding-bottom: 0.25rem">
                {{ company.companyName }}
              </div>
              <template v-for="mda in company.advertisers" :key="mda.advertiserId">
                <a-list-item v-if="mda.checked" class="list-item-level-2">
                  <template v-if="selectedChannel == 'TT'"> {{ mda.advertiserName }}({{ mda.advertiserId }})</template>
                  <template v-else> {{ mda.advertiserId }}</template>
                </a-list-item>
              </template>
            </a-list-item>
          </a-list>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { QuestionCircleFilled, SearchOutlined } from '@ant-design/icons-vue';
import { channelList } from '@/config/globalData.js';
import _ from 'lodash';
import { batchPushAccountList, batchPushToAccount } from '@/api/material/material.js';

export default {
  emits: ['modalConfirm', 'modalCancel', 'update:visible'],
  components: {
    SearchOutlined,
  },
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    materialList: {
      type: Array,
      default: () => [],
    },
    materialType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      accountLoading: false, //加载左侧账号列表
      pushing: false, // 正在请求推送接口
      channelList: channelList, // 媒体渠道列表
      selectedChannel: 'GDT', // 被选中的媒体渠道
      accountlistStorage: [], // 媒体账号本地缓存
      mdaList: [], // 当前选中渠道的媒体账号
      mdaListStorage: [], // 当前选中渠道的媒体账号本地缓存
      selectedMdaList: [], // 已选中的媒体账号
      selectedMdaListStorage: [], // 已选中的媒体账号本地缓存
      leftSearchKey: '', // 左侧搜索关键字
      rightSearchKey: '', // 右侧搜索关键字
    };
  },
  watch: {
    mdaListStorage: {
      handler(newVal) {
        if (newVal) {
          this.selectedMdaListStorage = this.selectedMdaList = newVal.filter(company => company.checkedAll || company.indeterminate);
        } else {
          this.selectedMdaListStorage = this.selectedMdaList = [];
        }
      },
      deep: true,
    },
    visible(newVal) {
      if (newVal) {
        this.accountLoading = true;
        batchPushAccountList(this.materialList.map(m => m.materialId)).then(res => {
          if (res.code === 0 && res.list && res.list.length > 0) {
            this.accountlistStorage = res.list;
            let channelObj = res.list.find(s => s.channel == this.selectedChannel);
            if (channelObj) {
              this.mdaListStorage = this.mdaList = channelObj.company;
            } else {
              this.mdaListStorage = this.mdaList = [];
            }
          }
          this.accountLoading = false;
        });
      }
    },
  },
  computed: {
    enabledChannel() {
      if (this.selectedMdaList.length > 0) return this.selectedChannel;
      else return '';
    },
    getSelectNum() {
      let sum = 0;
      this.selectedMdaList.forEach(company => {
        sum += company.advertisers.filter(mda => mda.checked).length;
      });
      return sum;
    },
  },
  methods: {
    async quickSelectAccount() {
      this.pushing = true;
      let res = await batchPushToAccount({
        materialType: this.materialType,
        mediaChannel: this.selectedChannel,
        material: this.materialList,
        mediaAccountList: [],
        pushToMe: 1,
      });
      if (res.code === 0) {
        this.pushSuccessCB();
      }
      this.pushing = false;
    },
    onTabChange(channel) {
      if (this.selectedMdaList.length > 0) return;
      this.selectedChannel = channel;
      let channelObj = this.accountlistStorage.find(s => s.channel == this.selectedChannel);
      if (channelObj) {
        this.mdaListStorage = this.mdaList = channelObj.company;
      } else {
        this.mdaListStorage = this.mdaList = [];
      }
    },
    onLeftSearchInputChange() {
      if (this.leftSearchKey == '') {
        this.onLeftSearchEnter();
      }
    },
    // 左侧搜索
    onLeftSearchEnter(e) {
      let _this = this;

      // 如果搜索关键字为空，还原列表
      if (_this.leftSearchKey == '') {
        _this.leftSearching = false;
        _this.mdaList = _this.mdaListStorage;
        return;
      }
      _this.leftSearching = true;

      _this.mdaList = _.cloneDeep(_this.mdaListStorage).filter(company => {
        let zhutiMatch = company.companyName.indexOf(_this.leftSearchKey) > -1;
        if (zhutiMatch) {
          return true;
        } else {
          let mdasFilter;
          if (this.selectedChannel === 'TT') {
            mdasFilter = company.advertisers.filter(a => a.advertiserName.indexOf(_this.leftSearchKey) > -1 || a.advertiserId.indexOf(_this.leftSearchKey) > -1);
          } else {
            mdasFilter = company.advertisers.filter(a => a.advertiserId.indexOf(_this.leftSearchKey) > -1);
          }
          if (mdasFilter.length > 0) {
            company.advertisers = mdasFilter;
            return true;
          } else {
            return false;
          }
        }
      });
    },
    onRightSearchInputChange() {
      if (this.rightSearchKey == '') {
        this.onRightSearchEnter();
      }
    },
    // 右侧搜索
    onRightSearchEnter(e) {
      let _this = this;

      // 如果搜索关键字为空，还原列表
      if (_this.rightSearchKey == '') {
        _this.selectedMdaList = _this.selectedMdaListStorage;
        return;
      }

      _this.selectedMdaList = _.cloneDeep(_this.selectedMdaListStorage).filter(company => {
        let zhutiMatch = company.companyName.indexOf(_this.rightSearchKey) > -1;
        if (zhutiMatch) {
          return true;
        } else {
          let mdasFilter = company.advertisers.filter(a => a.advertiserName.indexOf(_this.rightSearchKey) > -1 && a.checked);
          if (mdasFilter.length > 0) {
            company.advertisers = mdasFilter;
            return true;
          } else {
            return false;
          }
        }
      });
    },
    //主体被选中
    onCompanyChange(id) {
      let company = this.mdaList.find(company => company.companyName == id);
      let checkedAll = company.checkedAll;
      company.indeterminate = false;
      company.advertisers.forEach(a => (a.checked = checkedAll));
      if (this.leftSearching) {
        let zhutiStorage = this.mdaListStorage.find(company => company.companyName == id);
        zhutiStorage.checkedAll = checkedAll;
        zhutiStorage.indeterminate = false;
        zhutiStorage.advertisers.forEach(a => (a.checked = checkedAll));
      }
    },
    //媒体账号被选中
    onMdaChange(zhutiId, mdaId) {
      let company = this.mdaList.find(company => company.companyName == zhutiId);
      company.indeterminate = company.advertisers.some(a => a.checked) && !company.advertisers.every(a => a.checked);
      company.checkedAll = company.advertisers.every(a => a.checked);
      if (this.leftSearching) {
        let zhutiStorage = this.mdaListStorage.find(company => company.companyName == zhutiId);
        zhutiStorage.advertisers.find(a => a.advertiserId == mdaId).checked = company.advertisers.find(a => a.advertiserId == mdaId).checked;
        zhutiStorage.indeterminate = zhutiStorage.advertisers.some(a => a.checked) && !zhutiStorage.advertisers.every(a => a.checked);
        zhutiStorage.checkedAll = zhutiStorage.advertisers.every(a => a.checked);
      }
    },
    clearAll() {
      this.rightSearchKey = '';
      this.mdaList.forEach(company => {
        company.checkedAll = false;
        company.indeterminate = false;
        company.advertisers.forEach(a => (a.checked = false));
      });

      this.mdaListStorage.forEach(company => {
        company.checkedAll = false;
        company.indeterminate = false;
        company.advertisers.forEach(a => (a.checked = false));
      });
    },
    async onModalConfirm() {
      if (this.selectedMdaList.length === 0) {
        this.$message.warn('请至少选择一个账号');
        return;
      }
      this.pushing = true;
      let pushAccount = [];
      this.selectedMdaList.forEach(company => {
        pushAccount = pushAccount.concat(company.advertisers.filter(a => a.checked));
      });

      let res = await batchPushToAccount({
        materialType: this.materialType,
        mediaChannel: this.selectedChannel,
        material: this.materialList,
        mediaAccountList: pushAccount.map(a => Number.parseInt(a.advertiserId)),
        pushToMe: 0,
      });
      if (res.code === 0) {
        this.pushSuccessCB();
      }
      this.pushing = false;
    },
    onModalCancel() {
      this.beforeModalClose();
      this.$emit('modalCancel');
    },
    pushSuccessCB() {
      this.$message.success('推送任务已成功发送');
      this.beforeModalClose();
      this.$emit('modalConfirm');
    },
    beforeModalClose() {
      this.initialData();
      this.$emit('update:visible', false);
    },
    initialData() {
      this.mdaList = [];
      this.mdaListStorage = [];
      this.selectedMdaList = [];
      this.selectedMdaListStorage = [];
      this.leftSearchKey = '';
      this.rightSearchKey = '';
    },
  },
};
</script>

<style lang="less" scoped>
.left,
.right {
  flex: 1;
  width: 11.25rem;
  height: 14.625rem;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  border-radius: 4px;

  .header {
    background: #f5f7fa;
    height: 1.125rem;
    display: flex;
  }

  .search-input {
    margin: 0.375rem;
    flex: 1;
  }

  .ant-list-item {
    width: auto;
    margin: 0 0.68rem;
    padding: 0.25rem 0;

    .list-item-level-2 {
      position: relative;
      width: auto;
    }

    .label-text {
      margin-left: 0.5em;
    }
  }
}

.left {
  .header {
    .header-tab {
      position: relative;
      font-size: 0.4375rem;
      line-height: 1.125rem;
      flex: 1;
      text-align: center;
      height: 1.125rem;
      margin: 0 8px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .header-tab-disabled {
      color: #c2c2c2;
    }

    .tab-indicator {
      color: #2f70f4;
      border-bottom: solid 3px #2f70f4;
      width: 0%;
      height: 2px;
      transition: all 0.2s ease;
    }

    .tab-indicator-show {
      width: 88%;
    }
  }
}

.right {
  margin-left: 0.75rem;
  .header {
    align-items: center;
    padding: 0 0.35rem;
    label {
      flex: 1;
    }
    a {
      flex: 1;
      text-align: right;
    }
  }
}
</style>