/*
 * @Author: LiuXin
 * @Date: 2021-08-16 17:45:34
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-10-25 16:55:20
 */
// 主页echarts配置项（从上到下），可按需导入
import moment from 'moment';
const echartsOptions = {
  // 实时概览
  overview: {
    consumeRate: {
      tooltip: { trigger: 'item' },
      color: ['rgba(0, 126, 255, 1)', 'rgba(213, 112, 28, 1)'],
      series: [{ name: '', type: 'pie', radius: '75%', label: { show: false }, data: [] }],
    },
  },
  // 消耗对比
  consumeCompare: {
    // 产品余额与产品消耗比
    compare1: {
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' },
        formatter: function(params, ticket) {
          return params[1].name + '<br/>' + `总余额：${params[0].data}` + '<br/>' + `日均消耗：${params[1].data}` + '<br/>' + `总余额/日均消耗：${(params[0].data / params[1].data).toFixed(2)}`;
        },
      },
      grid: { top: 0, left: 10, right: 50, bottom: 30, containLabel: true },
      xAxis: { type: 'value', show: false },
      yAxis: { type: 'category', data: [], axisTick: { show: false }, axisLine: { show: false } },
      series: [
        { name: '总余额', type: 'bar', stack: 'total', label: { show: false }, emphasis: { focus: 'series' }, data: [] },
        { name: '日均消耗', barWidth: 24, type: 'bar', stack: 'total', label: { show: false }, emphasis: { focus: 'series' }, data: [] },
      ],
      dataZoom: [{ type: 'slider', show: true, width: 0, yAxisIndex: [0], top: '20', left: '95%', start: 50 }],
    },
    // 今日在投产品账号分布
    compare2: {
      tooltip: { trigger: 'item' },
      series: [{ name: '', type: 'pie', radius: '75%', label: { show: false }, data: [] }],
    },
    // 今日产品消耗
    compare3: {
      tooltip: { trigger: 'item' },
      grid: [{ left: 10, bottom: 70, top: 10, right: 10 }],
      yAxis: { show: false },
      series: [{ type: 'treemap', breadcrumb: { show: false }, data: [] }],
    },
    // 双饼图
    compare4: {
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)',
      },
      legend: [
        { data: [], left: '60%', top: '10' },
        { data: [], left: '60%', top: '50', itemGap: 30 },
      ],
      series: [
        {
          name: '素材类型',
          type: 'pie',
          selectedMode: 'single',
          radius: [0, '30%'],
          center: ['30%', '50%'],
          label: { position: 'inner', fontSize: 14 },
          data: [],
        },
        {
          name: '关联产品',
          type: 'pie',
          radius: ['45%', '60%'],
          center: ['30%', '50%'],
          // label: { show: false },
          data: [],
        },
      ],
      grid: [{ left: '50%', bottom: 70, top: 10, right: 10 }],
    },
    // 日历饼图
    compare5: {
      tooltip: {},
      calendar: {
        top: 'middle',
        left: 'center',
        orient: 'vertical',
        cellSize: [90, 65],
        yearLabel: {
          show: false,
          fontSize: 30,
        },
        dayLabel: {
          margin: 20,
          firstDay: 1,
          nameMap: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
        },
        monthLabel: {
          show: false,
        },
        range: [],
      },
      series: [
        {
          tooltip: { show: false },
          id: 'label',
          type: 'scatter',
          coordinateSystem: 'calendar',
          symbolSize: 0,
          label: {
            show: true,
            formatter: params => moment(params.data[0]).format('DD'),
            offset: [33, -20],
            fontSize: 14,
            color: '#666',
          },
          data: [],
        },
      ],
    },
  },
  //  近三十天消耗趋势
  trendChart: {
    trend1: {
      grid: [{ left: 52, bottom: 65, top: 10, right: 20 }],
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisTick: { show: false },
        data: [],
        axisLabel: {
          color: '#89889C',
          formatter: (value, index) => {
            const arr = value.split('-');
            return arr[1] + '-' + arr[2];
          },
        },
      },
      yAxis: {
        axisLabel: { formatter: value => (value > 10000 ? value / 10000 + ' 万' : value) },
        splitLine: { show: true, lineStyle: { color: ['#e6e9f1'], width: 1, type: 'solid' } },
      },
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          animation: false,
          label: {
            backgroundColor: '#505765',
          },
        },
      },
      legend: {
        data: [],
        bottom: '10',
        left: '25',
        right: '25',
        icon: 'circle',
        itemWidth: 10,
        itemHeight: 10,
        type: 'scroll', //关键地方
        itemGap: 20, //这个值是用来调图例之间的间距
      },
      series: [{ name: '', smooth: true, symbol: 'none', type: 'line', data: [] }],
    },
  },
  // 详情对比趋势
  trendDetailChart: {
    trend1: {
      grid: [{ left: 65, bottom: 65, top: 10, right: 60 }],
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          axisTick: { show: false },
          data: [],
          axisLabel: {
            color: 'rgba(0, 0, 0, 0.45)',
            onZero: false,
            formatter: (value, index) => {
              const arr = value.split('-');
              return arr[1] + '-' + arr[2];
            },
          },
        },
        {
          type: 'category',
          boundaryGap: false,
          axisTick: { show: false },
          axisLabel: { onZero: false, color: 'rgba(0, 0, 0, 0.45)' },
          data: [],
          offset: 0,
        },
      ],
      yAxis: [
        {
          // splitLine: { show: true, lineStyle: { color: ['#e6e9f1'], width: 1, type: 'solid' } },
          axisLabel: { formatter: value => (value > 10000 ? value / 10000 + ' 万' : value) },
        },
        {
          splitLine: { show: false, lineStyle: { color: ['#e6e9f1'], width: 1, type: 'solid' } },
          axisLabel: { formatter: value => (value > 10000 ? value / 10000 + '万' : value) },
          // max:500000,
        },
      ],
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          animation: false,
          label: {
            backgroundColor: '#505765',
          },
        },
      },
      legend: { data: ['曝光量', '花费'], bottom: '10', left: 'center', icon: 'circle', itemWidth: 10, itemHeight: 10, itemGap: 64, textStyle: { fontSize: 12, lineHeight: 48 } },
      series: [
        { xAxisIndex: 0, yAxisIndex: 0, name: '曝光量', smooth: true, symbol: 'none', type: 'line', data: [], lineStyle: { type: 'solid' }, itemStyle: { color: '#6A8ED8' } },
        { xAxisIndex: 0, yAxisIndex: 1, name: '花费', smooth: true, symbol: 'none', type: 'line', data: [], lineStyle: { type: 'solid' }, itemStyle: { color: '#06C86F' } },
        { xAxisIndex: 1, yAxisIndex: 0, name: '曝光量', smooth: true, symbol: 'none', type: 'line', data: [], lineStyle: { type: 'dashed' }, itemStyle: { color: '#6A8ED8' } },
        { xAxisIndex: 1, yAxisIndex: 1, name: '花费', smooth: true, symbol: 'none', type: 'line', data: [], lineStyle: { type: 'dashed' }, itemStyle: { color: '#06C86F' } },
      ],
    },
  },
};
export default echartsOptions;
