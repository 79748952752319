<!-- 设计部门组长（组员） -->
<template>
  <div class="sjbm_div">
    <div class="showDetail_div">
      <span>显示明细</span>
      <a-switch v-model:checked="amountShowDetail" />
    </div>
    <div class="row">
      <!-- 消耗 -->
      <div class="box">
        <div class="title">
          <span>消耗</span>
          <p class="text4">刷新倒计时: {{ countdownNum }} 秒</p>
        </div>
        <div class="content">
          <div class="text_description">
            <p class="text3">当日总消耗</p>
            <p class="consume_detail">
              <span class="text2">{{ formatNumber(dashboardCostData.cost) || 0 }}</span>
            </p>
          </div>
          <div class="text_description">
            <p class="text3">昨日消耗，环比昨日</p>
            <p class="consume_detail">
              <span class="text2">{{ formatNumber(dashboardCostData.yestDayCost) || 0 }} , {{ formatNumber(dashboardCostData.comparedToYesterday) || 0 }}</span>
            </p>
          </div>
          <div class="text_description">
            <p class="text3">本月总消耗</p>
            <p class="consume_detail">
              <span class="text2">{{ formatNumber(dashboardCostData.mothCost) || 0 }}</span>
            </p>
          </div>
        </div>
      </div>
      <!-- 制作数 -->
      <div class="box">
        <div class="title">
          <span>制作数</span>
        </div>
        <div class="content">
          <div class="text_description">
            <p class="text3">当周制作数</p>
            <p class="consume_detail">
              <span class="text2">{{ formatNumber(materialData.thisWeekMaterialCount) || 0 }}</span>
            </p>
          </div>
          <div class="text_description">
            <p class="text3">上周制作，环比</p>
            <p class="consume_detail">
              <span class="text2">{{ formatNumber(materialData.lastWeekMaterialCount) || 0 }} , {{ formatNumber(materialData.comparedToLastWeek) || 0 }}</span>
            </p>
          </div>
          <div class="text_description">
            <p class="text3">本月制作数</p>
            <p class="consume_detail">
              <span class="text2">{{ formatNumber(materialData.thisMothMaterialCount) || 0 }}</span>
            </p>
          </div>
        </div>
      </div>
      <!-- 目标 -->
      <div class="box">
        <div class="title">
          <span>目标</span>
          <div class="query" v-if="$store.state?.oauth?.userInfo?.grades == 'GROUPLEADER'">
            <a @click="openSzmbDialog">设置目标</a>
          </div>
        </div>
        <div class="content">
          <div class="text_description">
            <p class="text3">剩余每日需完成</p>
            <p class="consume_detail">
              <span class="text2">{{ formatNumber(targetCostData.todayTargetCost || 0) }}</span>
            </p>
          </div>
          <div class="text_description">
            <p class="text3">本月目标完成率</p>
            <p class="consume_detail">
              <span class="text2">{{ formatNumber(targetCostData.monthCompletionRate || 0) }}</span>
            </p>
          </div>
          <div class="text_description">
            <p class="text3">本月目标</p>
            <p class="consume_detail">
              <span class="text2">{{ formatNumber(targetCostData.monthTargetCost || 0 ) }}</span>
            </p>
          </div>
        </div>
        <a-modal v-model:visible="szmbDialog.visible" wrapClassName="szmbDialog" centered :keyboard="false" :maskClosable="false" :footer="null" :width="1100" title="设置目标" @ok="closeSzmbDialog">
          <div class="szmb_div">
            <a-spin :spinning="szmbDialog.loading">
              <a-tabs v-model:activeKey="szmbDialog.activeKey" @change="szmbActiveChange">
                <a-tab-pane key="DM" tab="人员目标"></a-tab-pane>
                <!-- <a-tab-pane key="DP" tab="项目目标"></a-tab-pane> -->
              </a-tabs>
              <div class="query">
                <a-input-search v-model:value="szmbDialog.gjz" placeholder="请输入关键字搜索" style="width: 400px" @search="queryDeptOrTargetList" />
                <div>
                  <a-button @click="editOrCancel" type="primary">{{ szmbDialog.edit == false ? '编辑' : '取消' }}</a-button>
                  <a-button :disabled="!szmbDialog.edit" type="primary" @click="batchSaveTarget">保存</a-button>
                </div>
              </div>
              <div class="table">
                <a-table :scroll="{ y: 300, x: '100%' }" :data-source="szmbDialog.dataSource" :columns="szmbDialog.columns">
                  <template #edit="{ record, column }">
                    <a-input-number v-if="szmbDialog.edit" v-model:value="record[column.editValue]" style="width: 100% " :min="1" :max="1000000000" />
                    <span v-else class="whiteSpaceNowrap" :title="formatNumber(record[column.key])">
                      {{ formatNumber(record[column.key]) }}
                    </span>
                  </template>
                  <template #showDetail="{ record, column }">
                    <span class="whiteSpaceNowrap" :title="formatNumber(record[column.key])">
                      {{ formatNumber(record[column.key]) }}
                    </span>
                  </template>
                </a-table>
              </div>
            </a-spin>
          </div>
        </a-modal>
      </div>
    </div>
    <div class="row" style="height: 500px">
      <!-- 消耗（等其他基础指标）详情 -->
      <div class="box">
        <div class="title">
          <span>消耗趋势</span>
          <div class="query">
            <a-checkbox v-model:checked="costInfo.checked" @change="costInfoCheckChange">全选</a-checkbox>
            <a-select
              ref="select"
              v-model:value="costInfo.query.latitude"
              style="width: 150px"
              @change="
                () => {
                  costInfo.checked = true;
                  queryCostInfo();
                }
              "
            >
              <a-select-option v-for="item in latitudeList" :key="item.name" :value="item.code">{{ item.name }}</a-select-option>
            </a-select>
            <a-select
              v-show="costInfo.query.latitude == 'USER'"
              ref="select"
              v-model:value="costInfo.query.jobPosition"
              style="width: 100px"
              @change="
                () => {
                  costInfo.checked = true;
                  queryCostInfo();
                }
              "
            >
              <a-select-option v-for="item in jobPositionList" :key="item.name" :value="item.code">{{ item.name }}</a-select-option>
            </a-select>
            <a-range-picker
              v-model:value="costInfo.time"
              value-format="YYYY-MM-DD"
              separator="至"
              @change="
                () => {
                  costInfo.checked = true;
                  queryCostInfo();
                }
              "
            >
            </a-range-picker>
          </div>
        </div>
        <div class="content">
          <div class="chart" ref="costInfo_chart"></div>
        </div>
      </div>
    </div>
    <div class="row" style="height: 400px">
      <!-- 制作详情 -->
      <div class="box">
        <div class="title">
          <span>制作详情</span>
          <div class="query">
            <a-select ref="select" v-model:value="makeInfo.query.latitude" style="width: 150px" @change="queryMakeInfo">
              <a-select-option v-for="item in simpleLatitudeList" :key="item.name" :value="item.code">{{ item.name }}</a-select-option>
            </a-select>
            <a-select v-show="makeInfo.query.latitude == 'USER'" ref="select" v-model:value="makeInfo.query.jobPosition" style="width: 100px" @change="queryMakeInfo">
              <a-select-option v-for="item in jobPositionList" :key="item.name" :value="item.code">{{ item.name }}</a-select-option>
            </a-select>
            <a-range-picker v-model:value="makeInfo.time" value-format="YYYY-MM-DD" separator="至" @change="queryMakeInfo"> </a-range-picker>
          </div>
        </div>
        <div class="content">
          <div class="chart" ref="makeInfo_chart"></div>
        </div>
      </div>
      <!-- 素材当日top5详情 -->
      <div class="box">
        <div class="title">
          <span>当日素材消耗Top5</span>
        </div>
        <div class="content">
          <div class="chart" ref="costInfoTop5_chart"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- 明细 -->
      <div class="box">
        <div class="title">
          <span>素材使用明细</span>
          <div class="query">

            <a-button :loading="excelExporting" :disabled="tableLoading" @click="exportMaterialUsageDetails()">
              导出Excel
            </a-button>

            <a-select  ref="select" defaultValue="" v-model:value="materialUsageDetails.query.mediaChannel" style="width: 150px" @change="queryMaterialUsageDetails">
              <a-select-option v-for="item in mediaChannelList" :key="item.name" :value="item.code" >{{ item.name }}</a-select-option>
            </a-select>

            <a-select ref="select" v-model:value="materialUsageDetails.query.latitude" style="width: 150px" @change="() => {
              materialUsageDetails.query.jobPosition = 'VIDEO';
              queryMaterialUsageDetails();
            }" >
              <a-select-option v-for="item in simpleLatitudeList" :key="item.name" :value="item.code">{{ item.name }}</a-select-option>
            </a-select>

            <a-select
              v-show="materialUsageDetails.query.latitude == 'USER'"
              ref="select"
              v-model:value="materialUsageDetails.query.jobPosition"
              style="width: 100px"
              @change="
                () => {
                  materialUsageDetails.checked = true;
                  queryMaterialUsageDetails();
                }
              "
            >
              <a-select-option v-for="item in jobPositionList" :key="item.name" :value="item.code">{{ item.name }}</a-select-option>
            </a-select>

            <a-range-picker v-model:value="materialUsageDetails.time" value-format="YYYY-MM-DD" separator="至" @change="queryMaterialUsageDetails"> </a-range-picker>
          </div>
        </div>
        <div class="content">
          <a-table style="width: 100%" :scroll="{ y: 300 }" :data-source="materialUsageDetails.dataSource" :columns="materialUsageDetails.columns">
            <template #showDetail="{ record, column }">
              <span class="whiteSpaceNowrap">
                {{ formatNumber(record[column.key]) }}
              </span>
            </template>
          </a-table>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- 素材未使用详情 -->
      <div class="box">
        <div class="title">
          <span>素材未使用详情</span>
          <div class="query">
            <a-button :loading="exportNotUseMaterialExporting" :disabled="exportNotUseMaterialLoading" @click="exportNotUseMaterial()">
              导出Excel
            </a-button>
            <a-select v-show="false" ref="select" v-model:value="listNotUseMaterial.query.latitude" style="width: 100px" @change="querylistNotUseMaterial">
              <a-select-option v-for="item in latitudeList" :key="item.name" :value="item.code">{{ item.name }}</a-select-option>
            </a-select>
            <a-range-picker v-model:value="listNotUseMaterial.time" value-format="YYYY-MM-DD" separator="至" @change="querylistNotUseMaterial"> </a-range-picker>
          </div>
        </div>
        <div class="content">
          <a-table style="width: 100%" :scroll="{ y: 300 }" :data-source="listNotUseMaterial.dataSource" :columns="listNotUseMaterial.columns" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/axios';
import * as echarts from 'echarts';
import { markRaw, ref } from 'vue';
import moment from 'moment';
import { message } from 'ant-design-vue';
// import {CloudDownloadOutlined} from '@ant-design/icons-vue';
export default {
  name: 'Sjbm',
  data() {
    return {
      exportNotUseMaterialExporting:false,
      exportNotUseMaterialLoading:false,
      tableLoading:false,
      excelExporting:false,
      // 显示明细
      amountShowDetail: false,
      unmountedFlag: true,
      countdownNum: -1,
      // 消耗
      dashboardCostData: {},
      // 制作数
      materialData: {},
      // 目标
      targetCostData: {},
      // 岗位集合
      jobPositionList: [
        { code: 'PRODUCER', name: '制片' },
        { code: 'SHOOTING', name: '摄像' },
        { code: 'VIDEO', name: '后期' },
        { code: 'SCREENWRITER', name: '编导' },
      ],
      // 维度集合
      latitudeList: [
        { code: 'ALL', name: '按照总消耗统计' },
        { code: 'USER', name: '按照人员统计' },
        { code: 'PROJECT', name: '按照产品统计' },
        { code: 'TYPE', name: '按照素材类型统计' },
        { code: 'CHANNEL', name: '按照渠道统计' },
      ],
      simpleLatitudeList: [
        { code: 'USER', name: '按照用户统计' },
        { code: 'PROJECT', name: '按照产品统计' },
        { code: 'TYPE', name: '按照素材类型统计' },
      ],
      mediaChannelList: [
        { code: '', name: '全渠道' },
        { code: 'TT', name: '巨量引擎' },
        { code: 'GDT', name: '腾讯广告' },
      ],
      // 制作详情
      makeInfo: {
        time: [
          moment()
            .subtract(1, 'weeks')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        query: {
          endDate: '',
          jobPosition: 'VIDEO',
          latitude: 'PROJECT',
          startDate: '',
        },
        chart: null,
        data: {
          dataset: {
            source: [],
          },
          legend: {
            type: 'scroll',
            orient: 'vertical',
            top: 'middle',
            left: '20',
            itemWidth: 17,
            itemHeight: 12,
            itemStyle: {
              fontSize: 14,
            },
          },
          tooltip: {
            trigger: 'item',
            formatter(params) {
              let back = `${params.name}`;
              const data = params.data;
              back += `<br>
                <span class="echartsName">${params.marker}</span>
                <span class="echartsValue">${data[1]}</span>`;
              return back;
            },
          },
          series: [
            {
              type: 'pie',
              selectedMode: 'single',
              left: '240',
              labelLine: { smooth: true },
              clockwise: true,
              cursor: 'auto',
              label: {
                show: false,
              },
              itemStyle: {
                borderColor: '#eee',
                borderWidth: 1,
              },
            },
          ],
        },
      },
      // 消耗详情
      costInfo: {
        checked: true,
        time: [
          moment()
            .subtract(1, 'months')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        query: {
          endDate: '',
          jobPosition: 'PRODUCER',
          latitude: 'ALL',
          startDate: '',
        },
        chart: null,
        data: {
          dataset: {
            source: [],
          },
          tooltip: {
            formatter: params => {
              if (params?.length) {
                let back = `${params[0].name}<br>`;
                params.forEach(item => {
                  back += `<span class="echartsDiv">
                      <span>${item.marker}${item.seriesName}</span>
                      <span class="echartsValue">${this.formatNumber(item.data[item.seriesIndex + 1])}</span>
                    </span>`;
                });
                return back;
              }
            },
          },
          legend: { top: 5, right: 5, selected: {} },
          grid: { top: '20%' },
          xAxis: { type: 'category' },
          yAxis: { type: 'value' },
          series: [],
        },
      },
      // 消耗TOP5
      costInfoTop5: {
        chart: null,
        data: {
          dataset: {
            source: [],
          },
          tooltip: {
            formatter: params => {
              if (params?.length) {
                let back = `${params[0].name}<br>`;
                params.forEach(item => {
                  back += `<span class="echartsDiv">
                      <span>${item.marker}</span>
                      <span class="echartsValue">${this.formatNumber(item.data[item.seriesIndex + 1])}</span>
                    </span>`;
                });
                return back;
              }
            },
          },
          legend: { show: false },
          grid: { top: '3%', bottom: '-4%' },
          xAxis: { type: 'value', show: false },
          yAxis: { type: 'category', show: true, inverse: true, axisLabel: { show: false } },
          series: [
            {
              type: 'bar',
              barMaxWidth: 20,
              showBackground: true,
              backgroundStyle: {
                color: '#eaeef1',
              },
              itemStyle: { color: '#3ba1ff', emphasis: { color: '#0a75d7' }, borderRadius: [0, 10, 10, 0] },
              label: { show: true, position: 'insideLeft', color: '#000', formatter: '{b}' },
            },
          ],
        },
      },
      // 明细
      materialUsageDetails: {
        checked: true,
        time: [
          moment()
            .subtract(1, 'weeks')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        query: {
          endDate: '',
          latitude: 'USER',
          jobPosition: 'VIDEO',
          // latitudes:['USER'],
          startDate: '',
        },
        dataSource: [],
        columns: [
          // { title: '用户', dataIndex: 'userName', key: 'userName' },
          // { title: '产品名', dataIndex: 'ofProjectName', key: 'ofProjectName' },
          { title: '项目', dataIndex: 'name', key: 'name' },
          { title: '消耗', dataIndex: 'cost', key: 'cost', slots: { customRender: 'showDetail' } },
          // { title: '目标', dataIndex: 'cost1', key: 'cost1' },
          // { title: '素材类型', dataIndex: 'materialChannel', key: 'materialChannel' },
          { title: '制作数', dataIndex: 'makeCount', key: 'makeCount', slots: { customRender: 'showDetail' } },
          { title: '使用数', dataIndex: 'usingCount', key: 'usingCount', slots: { customRender: 'showDetail' } },
          { title: '使用率', dataIndex: 'usingRate', key: 'usingRate' },
          { title: '有效数', dataIndex: 'effectiveCount', key: 'effectiveCount', slots: { customRender: 'showDetail' } },
          { title: '有效率', dataIndex: 'effectiveRate', key: 'effectiveRate' },
          { title: '首发数', dataIndex: 'firstCount', key: 'firstCount', slots: { customRender: 'showDetail' } },
          { title: '首发率', dataIndex: 'firstRate', key: 'firstRate' },
          { title: '首发有效数', dataIndex: 'firstEffectiveCount', key: 'firstEffectiveCount' },
          { title: '优质数', dataIndex: 'higtCount', key: 'higtCount', slots: { customRender: 'showDetail' } },
          { title: '优质有效数', dataIndex: 'higtEffectiveCount', key: 'higtEffectiveCount', slots: { customRender: 'showDetail' } },
          { title: '优质率', dataIndex: 'higtRate', key: 'higtRate' },
          // { title: 'arpu值', dataIndex: 'cost2', key: 'cost2' },
        ],
      },
      // 素材未使用详情
      listNotUseMaterial: {
        time: [
          moment()
            .subtract(1, 'weeks')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        query: {
          endDate: '',
          latitude: 'USER',
          startDate: '',
        },
        dataSource: [],
        columns: [
          { title: '素材名称', dataIndex: 'materialName', key: 'materialName' },
          { title: '项目', dataIndex: 'projectName', key: 'projectName', width: 200 },
          { title: '使用部门', dataIndex: 'deptName', key: 'deptName', width: 150 },
        ],
      },
      // 设置目标弹框
      szmbDialog: {
        visible: false,
        activeKey: 'DM',
        gjz: '',
        loading: false,
        edit: false,
        dataSource: [],
        editData: [],
        columns: [
          { title: 'ID', dataIndex: 'objPk', key: 'objPk' },
          { title: this.szmbDialogTableHeadName, dataIndex: 'objName', key: 'objName' },
          { title: '本月消耗', dataIndex: 'thisMothCost', key: 'thisMothCost', slots: { customRender: 'showDetail' } },
          { title: '本月目标', dataIndex: 'thisMothTargetCost', key: 'thisMothTargetCost', editValue: 'bymb', with: '220px', slots: { customRender: 'edit' } },
          { title: '上月消耗', dataIndex: 'lastMothCost', key: 'lastMothCost', slots: { customRender: 'showDetail' } },
          { title: '上月目标', dataIndex: 'lastMothTargetCost', key: 'lastMothTargetCost', slots: { customRender: 'showDetail' } },
          { title: '本季度消耗', dataIndex: 'thisQuarterCost', key: 'thisQuarterCost', slots: { customRender: 'showDetail' } },
          { title: '本季度目标', dataIndex: 'thisQuarterTargetCost', key: 'thisQuarterTargetCost', editValue: 'bjdmb', with: '220px', slots: { customRender: 'edit' } },
        ],
      },
    };
  },
  components: {},
  props: {},
  watch: {},
  computed: {},
  created() {
    this.getDashboardCost();
    this.longPolling();
  },
  mounted() {
    // 初始化
    this.init();
    // 监听界面宽度变化
    window.addEventListener('resize', this.resizeWindow);
  },
  unmounted() {
    this.unmountedFlag = false;
    // 销毁监听
    window.removeEventListener('resize', this.resizeWindow);
  },
  methods: {
    // 初始化
    init() {
      // 查询实时消耗-运营-设计 团队以及个人
      // this.getDashboardCost();
      // 查询素材制作情况
      this.getMaterial();
      // 目标跟踪-运营-设计 团队以及个人
      this.getTargetCost();
      // 制作详情-按照日期统计个人岗位、项目、类型纬度的制作数量
      this.queryMakeInfo();
      // 素材消耗分日趋势-视频团队按照日期统计个人岗位、项目、渠道、类型纬度的制作数量
      this.queryCostInfo();
      // 视频团队-今日素材消耗TOP5
      this.getCostInfo();
      // 视频部门-素材使用明细
      this.queryMaterialUsageDetails();
      // 未使用素材查询
      this.querylistNotUseMaterial();
    },
    szmbDialogTableHeadName() {
      return this.szmbDialog.activeKey == '1' ? '人员名称' : '项目名称';
    },
    formatNumber(num) {
      if (!num) return num;
      if (this.amountShowDetail) return num;
      num = Number(num);
      if (num == 0 || (num > 0 && num < 10000)) {
        return num + '';
      } else {
        return (num / 10000).toFixed(2) + '万';
      }
    },
    async sleepTime() {
      for (let i = 0; i < 15; i++) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        this.countdownNum--;
      }
    },
    async longPolling() {
      if (this.countdownNum < 0) {
        this.countdownNum = 15;
        while (this.countdownNum >= 0 && this.unmountedFlag) {
          this.countdownNum = 15;
          // alert("向服务器发送长轮询请求！");
          const res = await axios.get('/home/dashboard/cost');
          await this.sleepTime();
          // alert(res.data);
          this.dashboardCostData = res.data || {};
        }
      }
    },
    // 查询实时消耗-运营-设计 团队以及个人
    getDashboardCost() {
      axios.get('/home/dashboard/cost').then(res => {
        this.dashboardCostData = res.data || {};
      });
    },
    // 查询素材制作情况
    getMaterial() {
      axios.get('/home/dashboard/material').then(res => {
        this.materialData = res.data || {};
      });
    },
    // 目标跟踪-运营-设计 团队以及个人
    getTargetCost() {
      axios.get('/home/target/cost').then(res => {
        this.targetCostData = res.data || {};
      });
    },
    // 制作详情-按照日期统计个人岗位、项目、类型纬度的制作数量
    queryMakeInfo() {
      axios
        .post('/home/dashboard/makeInfo', {
          ...this.makeInfo.query,
          startDate: this.makeInfo.time[0] || '',
          endDate: this.makeInfo.time[1] || '',
        })
        .then(res => {
          this.makeInfo.data.dataset.source = [];
          // 组装二维数组
          const Source = [['', '']];
          res.data.forEach(item => {
            // 计算有几个X轴
            let find = false;
            Source.forEach(list => {
              if (list[0] === item.key) {
                find = true;
              }
            });
            // 如果没找到
            if (!find) {
              Source.push([item.key, item.val]);
            }
          });
          // 计算总数
          let allValue = 0;
          for (let i = 1; i < Source.length; i++) {
            const item = Source[i];
            allValue += item[1];
          }
          // 图例 展示百分比
          this.makeInfo.data.legend.formatter = name => {
            let value = 0;
            for (let i = 1; i < Source.length; i++) {
              const item = Source[i];
              if (item[0]) {
                if (item[0] === name) {
                  value = item[1];
                }
              } else if (!!item[0] === !!name) {
                value = item[1];
              }
            }
            return `${name}（${((value / allValue).toFixed(4) * 100).toFixed(2)}%）`;
          };
          // 图表数据源赋值
          this.makeInfo.data.dataset.source = Source;
          if (this.makeInfo.chart) {
            // 如果存在，销毁图表
            this.makeInfo.chart.dispose();
          }
          this.$nextTick(() => {
            this.makeInfo.chart = markRaw(echarts.init(this.$refs.makeInfo_chart));
            const option = this.echartsInit(this.makeInfo.data);
            this.makeInfo.chart.setOption(option);
          });
        });
    },
    // 素材消耗分日趋势-视频团队按照日期统计个人岗位、项目、渠道、类型纬度的制作数量
    queryCostInfo() {
      axios
        .post('/dashboard/costInfo', {
          ...this.costInfo.query,
          startDate: this.costInfo.time[0] || '',
          endDate: this.costInfo.time[1] || '',
        })
        .then(res => {
          this.costInfo.data.dataset.source = [];
          this.costInfo.data.series = [];
          this.costInfo.data.legend.selected = {};
          // 组装二维数组
          const Source = [['']];
          res.data.forEach(item => {
            // 计算有几个Y轴
            if (!Source[0].includes(item.objName)) {
              Source[0].push(item.objName);
              this.costInfo.data.series.push({ type: 'line', showSymbol: false, smooth: true });
              // 默认显示所有
              this.costInfo.data.legend.selected[item.objName] = true;
            }
            // 计算有几个X轴
            let find = false;
            Source.forEach(list => {
              if (list[0] === item.statDate) {
                find = true;
              }
            });
            // 如果没找到
            if (!find) {
              Source.push([item.statDate]);
            }
          });
          // 循环写入数据
          for (let i = 1; i < Source.length; i++) {
            const arr = Source[i];
            Source[0].forEach((y, index) => {
              if (index !== 0) {
                const isFind = res.data.find(data => arr[0] === data.statDate && y === data.objName);
                arr[index] = isFind ? isFind.cost : 0;
              }
            });
          }
          // 图表数据源赋值
          this.costInfo.data.dataset.source = Source;
          if (this.costInfo.chart) {
            // 如果存在，销毁图表
            this.costInfo.chart.dispose();
          }
          this.$nextTick(() => {
            this.costInfo.chart = markRaw(echarts.init(this.$refs.costInfo_chart));
            const option = this.echartsInit(this.costInfo.data);
            this.costInfo.chart.setOption(option);
          });
        });
    },
    // 全选、反选
    costInfoCheckChange() {
      for (const key in this.costInfo.data.legend.selected) {
        // const item = this.costInfo.data.legend.selected[key];
        this.costInfo.data.legend.selected[key] = this.costInfo.checked;
      }
      const option = this.echartsInit(this.costInfo.data);
      this.costInfo.chart.setOption(option);
    },
    // 视频团队-今日素材消耗TOP5
    getCostInfo() {
      axios.get('/dashboard/team_material_top_5').then(res => {
        this.costInfoTop5.data.dataset.source = [];
        // 组装二维数组
        const Source = [['', '']];
        res.data.forEach(item => {
          Source.push([item.materialName, item.cost]);
        });
        console.log(Source);
        // 图表数据源赋值
        this.costInfoTop5.data.dataset.source = Source;
        if (this.costInfoTop5.chart) {
          // 如果存在，销毁图表
          this.costInfoTop5.chart.dispose();
        }
        this.$nextTick(() => {
          this.costInfoTop5.chart = markRaw(echarts.init(this.$refs.costInfoTop5_chart));
          const option = this.echartsInit(this.costInfoTop5.data);
          this.costInfoTop5.chart.setOption(option);
        });
      });
    },
    echartsInit(dataSource) {
      let xAxis = {
        type: 'category',
        axisTick: { show: false },
        axisLine: { show: false },
      };
      if (dataSource.xAxis) {
        if (Object.prototype.toString.call(dataSource.xAxis) === '[object Object]') {
          xAxis = { ...xAxis, ...dataSource.xAxis };
        } else if (Object.prototype.toString.call(dataSource.xAxis) === '[object Array]') {
          xAxis = dataSource.xAxis.map(item => {
            return {
              axisTick: { show: false },
              axisLine: { show: false },
              ...item,
            };
          });
        }
      }
      let yAxis = {
        type: 'value',
        axisTick: { show: false },
        axisLine: { show: false },
      };
      if (dataSource.yAxis) {
        if (Object.prototype.toString.call(dataSource.yAxis) === '[object Object]') {
          yAxis = { ...yAxis, ...dataSource.yAxis };
        } else if (Object.prototype.toString.call(dataSource.yAxis) === '[object Array]') {
          yAxis = dataSource.yAxis.map(item => {
            return {
              axisTick: { show: false },
              axisLine: { show: false },
              ...item,
            };
          });
        }
      }
      const options = {
        ...dataSource,
        legend: { ...dataSource.legend },
        tooltip: {
          trigger: 'axis',
          ...dataSource.tooltip,
        },
        grid: {
          top: '7%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
          ...dataSource.grid,
        },
        xAxis: xAxis,
        yAxis: yAxis,
      };
      return options;
    },
    resizeWindow() {
      if (this.makeInfo.chart) this.makeInfo.chart.resize();
      if (this.costInfo.chart) this.costInfo.chart.resize();
      if (this.costInfoTop5.chart) this.costInfoTop5.chart.resize();
    },
    // 视频部门-素材使用明细
    queryMaterialUsageDetails() {
      if(this.materialUsageDetails.query.latitude != 'USER'){
        this.materialUsageDetails.query.jobPosition = null;
      }
      axios
        .post('/dashboard/materialUsageDetails', {
          ...this.materialUsageDetails.query,
          startDate: this.materialUsageDetails.time[0] || '',
          endDate: this.materialUsageDetails.time[1] || '',
        })
        .then(res => {
          this.materialUsageDetails.dataSource = res.data;
        });
    },
    exportMaterialUsageDetails(){
      this.tableLoading = true;
      this.excelExporting = true;
      try {
        axios.post('/dashboard/exportMaterialUsageDetails', {
          ...this.materialUsageDetails.query,
          startDate: this.materialUsageDetails.time[0] || '',
          endDate: this.materialUsageDetails.time[1] || '',
        }, {
          timeout: 60 * 1000,
          withCredentials: true,
          responseType: 'blob',
        }).then(res => {
          let blob = new Blob([res], { type: 'application/vnd.ms-excel' });
          let objectUrl = URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = objectUrl;
          link.download = '素材使用明细('+this.materialUsageDetails.time[0] +'~'+this.materialUsageDetails.time[1]+').xls';
          link.click();
          URL.revokeObjectURL(objectUrl);
          this.tableLoading = false;
          this.excelExporting = false;
        });
      }catch (err){
        this.tableLoading = false;
        this.excelExporting = false;
      }

    },
    exportNotUseMaterial(){
      this.exportNotUseMaterialExporting = true ;
      this.exportNotUseMaterialLoading = true;
      try {
        axios
            .post('/dashboard/exportNotUseMaterial', {
              ...this.listNotUseMaterial.query,
              startDate: this.listNotUseMaterial.time[0] || '',
              endDate: this.listNotUseMaterial.time[1] || '',
            },{
              timeout: 60 * 1000,
              withCredentials: true,
              responseType: 'blob',
            })
            .then(res => {
              let blob = new Blob([res], { type: 'application/vnd.ms-excel' });
              let objectUrl = URL.createObjectURL(blob);

              const link = document.createElement('a');
              link.href = objectUrl;
              link.download = '素材未使用详情('+this.materialUsageDetails.time[0] +'~'+this.materialUsageDetails.time[1]+').xls';
              link.click();
              URL.revokeObjectURL(objectUrl);
              this.exportNotUseMaterialExporting = false ;
              this.exportNotUseMaterialLoading = false;
            });
      }catch (err){
        this.exportNotUseMaterialExporting = false ;
        this.exportNotUseMaterialLoading = false;
      }
    },
    // 未使用素材查询
    querylistNotUseMaterial() {
      axios
        .post('/dashboard/listNotUseMaterial', {
          ...this.listNotUseMaterial.query,
          startDate: this.listNotUseMaterial.time[0] || '',
          endDate: this.listNotUseMaterial.time[1] || '',
        })
        .then(res => {
          this.listNotUseMaterial.dataSource = res.data;
        });
    },
    // 打开设置目标弹框
    openSzmbDialog() {
      this.szmbDialog.visible = true;
      this.szmbDialog.edit = false;
      this.szmbDialog.activeKey = 'DM';
      // 查询部门产品OR部门成员目标
      this.queryDeptOrTargetList();
    },
    // 关闭设置目标弹框
    closeSzmbDialog() {
      this.szmbDialog.visible = false;
    },
    // 设置目标弹框tab页切换
    szmbActiveChange() {
      // 查询部门产品OR部门成员目标
      this.queryDeptOrTargetList();
      this.szmbDialog.edit = false;
    },
    // 编辑/取消
    editOrCancel() {
      this.szmbDialog.edit = !this.szmbDialog.edit;
      // 取消
      if (!this.szmbDialog.edit) {
        this.szmbDialog.dataSource.forEach(item => {
          if (item.bymb) item.bymb = item.thisMothTargetCost;
          if (item.bjdmb) item.bjdmb = item.thisQuarterTargetCost;
        });
      }
    },
    // 查询部门产品OR部门成员目标
    queryDeptOrTargetList() {
      this.szmbDialog.loading = true;
      axios
        .post('/cost_target/dept_target_list', {
          objName: this.szmbDialog.gjz,
          objType: this.szmbDialog.activeKey, // DP:部门项目、DM：部门成员
        })
        .then(res => {
          this.szmbDialog.dataSource = res.data?.map(item => {
            return {
              ...item,
              bymb: item.thisMothTargetCost, // 本月目标
              bjdmb: item.thisQuarterTargetCost, // 本季度目标
            };
          });
        })
        .finally(() => {
          this.szmbDialog.loading = false;
        });
    },
    // 查询部门产品OR部门成员目标
    batchSaveTarget() {
      this.szmbDialog.loading = true;
      const arr = this.szmbDialog.dataSource.map(item => {
        return {
          objName: item.objName,
          objPk: item.objPk,
          objType: this.szmbDialog.activeKey,
          thisMothTargetCost: this.szmbDialog.edit ? item.bymb : item.thisMothTargetCost,
          thisQuarterTargetCost: this.szmbDialog.edit ? item.bjdmb : item.thisQuarterTargetCost,
        };
      });
      axios
        .post('/cost_target/batch_save_target', arr)
        .then(res => {
          if (res.code === 0) {
            message.success('保存成功');
            // 关闭设置目标弹框
            this.closeSzmbDialog();
            // 初始化
            this.init();
          }
        })
        .finally(() => {
          this.szmbDialog.loading = false;
        });
    },
  },
};
</script>
<style lang="less">
.echartsDiv {
  display: flex;
  justify-content: space-between;
  .echartsValue {
    margin-left: 20px;
  }
}
</style>
<style lang="less" scoped>
.whiteSpaceNowrap {
  white-space: nowrap;
}
.sjbm_div {
  width: 100%;
  .showDetail_div {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    > span {
      margin-right: 10px;
      font-size: 12px;
    }
  }
  .row {
    display: flex;
    .box {
      display: flex;
      flex-direction: column;
      flex: 1;
      background: #fff;
      padding: 12px;
      flex-shrink: 0;
      margin-bottom: 10px;
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        .query {
          * + * {
            margin-left: 5px;
          }
          .ant-calendar-picker {
            width: 185px;
          }
        }
        > span::before {
          content: '';
          display: inline-block;
          height: 12px;
          width: 4px;
          background-color: rgb(47, 112, 244);
          margin-right: 10px;
          transform: translateY(1px);
        }
      }
      & + .box {
        margin-left: 12px;
      }
    }
    .content {
      display: flex;
      align-items: center;
      flex: 1;
      .chart {
        width: 100%;
        height: 100%;
      }
    }
    .text_description {
      height: 90px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & + .text_description {
        margin-left: 15px;
      }
      .consume_detail {
        margin-top: 10px;
      }
      .status_icon {
        .anticon-caret-up {
          color: rgba(19, 202, 92, 1);
        }
        .anticon-caret-down {
          color: rgba(215, 12, 12, 1);
        }
      }
      .text1 {
        font-size: 14px;
        color: #666666;
      }
      .text2 {
        font-size: 24px;
        font-weight: bold;
        white-space: nowrap;
      }
      .text3 {
        font-size: 12px;
        color: #666666;
      }
      .text4 {
        font-weight: bold;
        color: rgba(0, 0, 0, 1);
        font-size: 24px;
      }
    }
  }
}
</style>
