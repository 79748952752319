<!--
 * @Author: LiuXin
 * @Date: 2021-09-08 16:09:20
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-10-29 14:12:07
-->
<template>
  <div class="w100 h100 consume_table home_card">
    <!-- 模块顶部 -->
    <p class="module_title">
      <span class="title_content">
        <span> {{ titleObj[filters.type] }} </span>
        <a-dropdown>
          <a class="ant-dropdown-link" @click.prevent> <DownOutlined /> </a>
          <template #overlay>
            <a-menu @click="({ key }) => (filters.type = key)">
              <a-menu-item key="product"> <a href="javascript:;">产品消耗</a> </a-menu-item>
              <a-menu-item key="user"> <a href="javascript:;">运营消耗</a> </a-menu-item>
              <a-menu-item key="account"> <a href="javascript:;">账户消耗</a> </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </span>
      <!-- 筛选项 -->
      <span class="select_group">
        <a-select :options="channelList" v-model:value="filters.channel"></a-select>
        <!-- 产品、运营消耗仅显示部门筛选，账户消耗显示运营人员和产品筛选 -->
        <a-select placeholder="请选择部门" v-show="filters.type != 'account'" v-model:value="filters.dept" v-bind="$filterOptions" allow-clear>
          <a-select-option v-for="dep in deptList" :key="dep.deptId" :value="dep.deptId" :label="dep.name">{{ dep.name }}</a-select-option>
        </a-select>
        <a-select placeholder="请选择产品" v-show="filters.type == 'account' && productList.length" v-model:value="filters.productCode" v-bind="$filterOptions" allow-clear>
          <a-select-option v-for="product in productList" :key="product.projectCode" :value="product.projectCode" :label="product.projectName">{{ product.projectName }}</a-select-option>
        </a-select>
        <a-select placeholder="请选择运营" v-show="filters.type == 'account' && operatorList.length" v-model:value="filters.userId" v-bind="$filterOptions" allow-clear>
          <a-select-option v-for="operator in operatorList" :key="operator.userId" :value="operator.userId" :label="operator.username">{{ operator.username }}</a-select-option>
        </a-select>
        <a-range-picker :allow-clear="false" v-model:value="dateRange" style="width: 180px" value-format="YYYY-MM-DD" />
      </span>
    </p>
    <!-- 表格区域 -->
    <div class="table_wrapper w100" ref="tableWrapper">
      <a-table size="small" :columns="tableColumns" :data-source="tableData" v-bind="table" @change="onTableChange"> </a-table>
      <div class="load_more_wrapper" v-show="false">
        <div ref="loadMore" class="load_more" v-show="!table.loading && tableData.length">
          <span v-if="!table.loadOver"> 正在加载 <LoadingOutlined /></span>
          <span v-else-if="showNoMore"> 没有更多了</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DownOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import { channelList } from '@/config/globalData.js';
import { getConsumeData } from '@/api/dashboard/index.js';
import moment from 'moment';
import { LoadMore } from '@/utils/LoadMore.js';
export default {
  name: 'OperatorTable',
  components: { DownOutlined, LoadingOutlined },
  props: {
    departmentData: { type: Array, default: () => [] },
    defaultType: { type: String, default: '' },
  },
  inject: ['tableColumn'],
  data() {
    // const deptId = this.$store.state.oauth.userInfo.deptId; //默认部门为当前登录人员部门
    const type = this.defaultType; //默认消耗类型 左边默认产品，右边默认运营
    let { deptList, operatorList, projectList } = this.$store.state.dorpDownData;
    return {
      titleObj: { product: '产品消耗', user: '运营消耗', account: '账户消耗' },
      deptList: deptList, //部门下拉列表
      operatorList: operatorList, //运营人员下拉列表
      productList: projectList, //产品下拉列表
      channelList, //媒体渠道下拉列表
      // 筛选条件
      filters: {
        type: type,
        channel: this.$store.state.userChannel,
        startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        dept: undefined,
        productCode: undefined,
        userId: undefined,
        order: 'desc',
        orderBy: 'cost',
      },
      tableData: [], //表格数据
      loadEx: null, //滚动监听示例
      showNoMore: false, // 显示  ‘没有更多了’ 提示
      // 表格配置项
      table: {
        rowkey: 'id',
        scroll: { y: 230 },
        loading: false,
        tableSorter: { order: '', orderType: 'desc' },
        allPages: 1,
        page: '1', //不能将page放入filters，因为更改筛选项时需要将page设为1，会触发两次watcher
        pageSize: 10,
        loadOver: false,
        pagination: false,
      },
    };
  },
  computed: {
    // 时间范围
    dateRange: {
      get() {
        return [this.filters.startDate, this.filters.endDate];
      },
      set(val) {
        this.filters.startDate = val[0];
        this.filters.endDate = val[1];
      },
    },
    // 表格表头
    tableColumns() {
      return this.tableColumn[this.filters.type];
    },
  },
  watch: {
    filters: {
      async handler() {
        this.tableData = [];
        this.table.loadOver = false;
        this.table.page = '1'; //修改筛选条件时当前页设置为1
        const tableData = await this.getTableData();
        this.tableData = tableData;
        this.loadEx.handleObserve();
      },
      deep: true,
    },
    // '没有更多了'提示字样只显示三秒
    'table.loadOver'(val) {
      if (val && this.table.allPages > 1) {
        this.showNoMore = true;
        setTimeout(() => {
          this.showNoMore = false;
        }, 3000);
      }
    },
  },
  async created() {
    const tableData = await this.getTableData();
    this.tableData = tableData;
  },
  mounted() {
    this.initObserve();
  },
  methods: {
    // 获取数据(仅处理后返回数据，不会设置数据)
    async getTableData() {
      this.table.loading = true;
      const postData = { ...this.filters, page: this.table.page, pageSize: this.table.pageSize };
      // 媒体消耗才可选产品与运营
      if (postData.type != 'account') {
        delete postData.productCode;
        delete postData.userId;
      }
      const res = await getConsumeData(postData);
      const data = res.data ? res.data.records : [];
      data.forEach(item => {
        // 账号
        item.advertiser = item.advertiser_name ? item.advertiser_name + '--' + item.advertiser_id : item.advertiser_id;
        // 涨幅  昨日消耗-前日消耗
        item.increase = (item.cost - item.b_cost).toFixed(2);
        // 环比 （昨日消耗-前日消耗）/前日消耗
        if (item.b_cost) {
          item.chainRatio = (item.increase / item.b_cost).toFixed(2);
        } else {
          item.chainRatio = '-';
        }
        item.key = this.$UUID.v1();
      });
      this.table.allPages = res.data?.pages || 1;
      this.table.loading = false;
      return data;
    },
    // 翻页及排序
    onTableChange(pagination, filters, sorter) {
      this.table.page = '1';
      this.filters.order = this.table.tableSorter.order = sorter.order == 'ascend' ? 'asc' : 'desc';
      this.filters.orderBy = this.table.tableSorter.orderType = sorter.field;
    },
    // 滚动加载
    async getTableDataByScroll() {
      if (this.table.page == this.table.allPages) {
        this.table.loadOver = true;
        return;
      }
      this.table.page = parseInt(this.table.page) + 1 + '';
      const tableData = await this.getTableData();
      this.tableData.splice(this.tableData.length, ...tableData);
    },
    // 初始化滚动监听
    initObserve() {
      this.table.loadOver = false;
      const tableWrapper = this.$refs.tableWrapper;
      const rootNode = tableWrapper.getElementsByClassName('ant-table-body')[0];
      rootNode.appendChild(this.$refs.loadMore);
      let loadEx = new LoadMore({
        root: rootNode,
        obs: this.$refs.loadMore,
        loadMoreFn: () => {
          if (this.table.loadOver) {
            loadEx.cancelObserve();
          } else {
            setTimeout(() => {
              this.getTableDataByScroll();
            }, 300);
          }
        },
      });
      this.loadEx = loadEx;
    },
  },
};
</script>

<style scoped lang="less">
.consume_table {
  display: flex;
  flex-direction: column;
  .select_group {
    & > * {
      width: 108px;
      margin-right: 16px;
    }
  }
  .table_wrapper {
    padding-top: 0;
    .load_more {
      width: 100%;
      line-height: 40px;
      border-bottom: 1px solid rgb(240, 240, 240);
      text-align: center;
    }
  }
}
</style>
