<!--
 * @Author: LiuXin
 * @Date: 2021-09-07 14:38:48
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-10-12 15:41:09
-->
<template>
  <div class="home_chart w100 h100" ref="homeChart">
    <!-- chart图容器 -->
    <div class="chart_wrapper w100" ref="chartContainer"></div>
  </div>
</template>

<script>
import { InitChart } from '@/utils/utils.js';
export default {
  name: 'HomeChart',
  props: {
    chartOption: { type: Object, default: () => {} }, //图表配置
  },
  data() {
    return {
      chartInstance: null,
    };
  },
  watch: {
    // 监听chart配置变化重绘图形
    chartOption: {
      handler() {
        if (this.chartInstance?._disposed) {
          return;
        }
        if (this.chartOption.getPieSeriesByChart) {
          const series = this.chartOption.getPieSeriesByChart(this.chartInstance);
          this.chartInstance.setOption({ series: series });
        } else {
          setTimeout(() => {
            this.chartInstance = new InitChart(this.chartOption, this.$refs.chartContainer);
          }, 0);
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="less">
.home_chart {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  .chart_wrapper {
    height: calc(100% - 42px);
    flex-grow: 1;
  }
}
</style>
