/*
 * @Author: chenxing
 * @Date: 2021-04-28 15:39:54
 * @LastEditors: zhangruifeng
 * @LastEditTime: 2021-06-23 17:19:23
 */
import axios from '@/utils/axios';

const apiUrl = {
    getDeptListByType: '/sys/dept/getList',
    getListByDeptTypes: '/sys/dept/getListByDeptTypes'
};

export function getDeptListDataByType(params) {
    return axios.post(apiUrl.getDeptListByType, params);
}

export function getListByDeptTypesData(params) {
    return axios.post(apiUrl.getListByDeptTypes, params);
}