const mediaTableColumns = {
  // case '关联媒体账号'
  glmtzh: [
    {
      title: '媒体账号ID',
      dataIndex: 'accountId',
      key: 'accountId',
      width: 100,
    },
    {
      title: '产品名称',
      dataIndex: 'projectName',
      key: 'projectName',
      width: 100,
    },
    {
      title: '消耗',
      dataIndex: 'cost',
      key: 'cost',
      sorter: true,
      width: 100,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'cost',
      },
    },
    // {
    //   title: '媒体账户状态',
    //   dataIndex: 'state',
    //   key: 'state',
    //   slots: {
    //     customRender: 'state',
    //   },
    // },
    {
      title: '当前运营',
      width: 100,
      dataIndex: 'operaterName',
      key: 'operaterName',
      slots: {
        customRender: 'operaterName',
      },
    },
  ],
  // case '关联推广计划':
  gltgjh: [
    {
      title: '媒体账号ID',
      dataIndex: 'accountId',
      key: 'accountId',
      width: 100,
    },
    {
      title: '推广计划ID',
      dataIndex: 'campaignId',
      key: 'campaignId',
      width: 100,
    },
    // {
    //   title: '推广计划状态',
    //   dataIndex: 'projectName',
    //   key: 'projectName',
    // },
    {
      title: '消耗',
      dataIndex: 'cost',
      key: 'cost',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'cost',
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'createTime',
      },
    },
    {
      title: '当前运营',
      dataIndex: 'operaterName',
      key: 'operaterName',
      width: 100,
      slots: {
        customRender: 'operaterName',
      },
    },
  ],
  // case '关联广告':
  glgg: [
    {
      title: '媒体账号ID',
      dataIndex: 'accountId',
      key: 'accountId',
      width: 100,
    },
    {
      title: '广告ID',
      dataIndex: 'adId',
      key: 'adId',
      width: 100,
    },
    // {
    //   title: '广告投放状态',
    //   dataIndex: 'invoiceName',
    //   key: 'invoiceName',
    //   slots: {
    //     customRender: 'invoiceName',
    //   },
    // },
    {
      title: '消耗',
      dataIndex: 'cost',
      key: 'cost',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'cost',
      },
    },
    {
      title: '版位信息',
      dataIndex: 'inventoryType',
      key: 'inventoryType',
      width: 100,
      slots: {
        customRender: 'inventoryType',
      },
    },
    {
      title: '曝光量',
      dataIndex: 'viewCount',
      key: 'viewCount',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'viewCount',
      },
    },
    {
      title: '点击率',
      dataIndex: 'ctr',
      width: 100,
      key: 'ctr',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'ctr',
      },
    },
    {
      title: '转化率',
      dataIndex: 'conversionsRate',
      key: 'conversionsRate',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'conversionsRate',
      },
    },

    {
      title: '转化成本',
      dataIndex: 'conversionsCost',
      key: 'conversionsCost',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'conversionsCost',
      },
    },
    {
      title: '千次展现均价',
      dataIndex: 'thousandDisplayPrice',
      key: 'thousandDisplayPrice',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'thousandDisplayPrice',
      },
    },
    {
      title: '创建时间',
      dataIndex: 'campaignCreateTime',
      key: 'campaignCreateTime',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'campaignCreateTime',
      },
    },
    {
      title: '当前运营',
      dataIndex: 'operaterName',
      key: 'operaterName',
      width: 100,
      slots: {
        customRender: 'operaterName',
      },
    },
  ],
  // case '关联广告创意':
  glggcy: [
    {
      title: '媒体账号ID',
      dataIndex: 'accountId',
      key: 'accountId',
      width: 100,
    },
    {
      title: '广告创意名称',
      dataIndex: 'adcreativeName',
      key: 'adcreativeName',
      width: 100,
    },
    {
      title: '广告创意ID',
      dataIndex: 'adcreativeId',
      key: 'adcreativeId',
      width: 100,
    },
    // {
    //   title: '广告创意投放状态',
    //   dataIndex: 'invoiceName',
    //   key: 'invoiceName',
    //   slots: {
    //     customRender: 'invoiceName',
    //   },
    // },
    {
      title: '消耗',
      dataIndex: 'cost',
      key: 'cost',
      sorter: true,
      width: 100,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'cost',
      },
    },
    {
      title: '曝光量',
      dataIndex: 'viewCount',
      key: 'viewCount',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'viewCount',
      },
    },
    {
      title: '点击率',
      dataIndex: 'ctr',
      width: 100,
      key: 'ctr',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'ctr',
      },
    },
    {
      title: '转化率',
      dataIndex: 'conversionsRate',
      key: 'conversionsRate',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'conversionsRate',
      },
    },
    {
      title: '转化成本',
      dataIndex: 'conversionsCost',
      key: 'conversionsCost',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'conversionsCost',
      },
    },
    {
      title: '千次展现均价',
      dataIndex: 'thousandDisplayPrice',
      key: 'thousandDisplayPrice',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'thousandDisplayPrice',
      },
    },
    {
      title: '创建时间',
      dataIndex: 'creativeCreateTime',
      key: 'creativeCreateTime',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'creativeCreateTime',
      },
    },
    // {
    //   title: '投放日期',
    //   dataIndex: 'date',
    //   key: 'date',
    //   width: 100,
    //   sorter: true,
    //   sortDirections: ['descend', 'ascend'],
    //   slots: {
    //     customRender: 'date',
    //   },
    // },
    {
      title: '当前运营',
      dataIndex: 'operaterName',
      key: 'operaterName',
      width: 100,
      slots: {
        customRender: 'operaterName',
      },
    },
  ],
  Gg2: [
    {
      title: '媒体账号ID',
      dataIndex: 'accountId',
      key: 'accountId',
      width: 100,
    },
    {
      title: '数据产生时间',
      dataIndex: 'date',
      key: 'date',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'stat_date',
      },
    },
    {
      title: '广告名称',
      dataIndex: 'adName',
      key: 'adName',
      width: 150,
    },
    // {
    //   title: '素材名称',
    //   dataIndex: 'materialName',
    //   key: 'materialName',
    //   width: 100,
    // },
    {
      title: '版位',
      dataIndex: 'appCode',
      key: 'appCode',
      width: 100,
    },
    {
      title: '消耗',
      dataIndex: 'cost',
      key: 'cost',
      sorter: true,
      width: 100,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'stat_cost',
      },
    },
    {
      title: '曝光量',
      dataIndex: 'viewCount',
      key: 'viewCount',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'viewCount',
      },
    },
    {
      title: '点击率',
      dataIndex: 'ctr',
      width: 60,
      key: 'ctr',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'ctr',
      },
    },
    {
      title: '转化率',
      dataIndex: 'conversionsRate',
      key: 'conversionsRate',
      width: 60,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'conversionsRate',
      },
    },
    {
      title: '转化成本',
      dataIndex: 'conversionsCost',
      key: 'conversionsCost',
      width: 80,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'conversionsCost',
      },
    },
    {
      title: '千次展现均价',
      dataIndex: 'thousandDisplayPrice',
      key: 'thousandDisplayPrice',
      width: 80,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'thousandDisplayPrice',
      },
    },
    {
      title: '当前运营',
      dataIndex: 'operaterName',
      key: 'operaterName',
      width: 100,
      slots: {
        customRender: 'operaterName',
      },
    },
  ],
  // case '关联广告组快手':
  glggzKS: [
    {
      title: '媒体账号ID',
      dataIndex: 'accountId',
      key: 'accountId',
      width: 100,
    },
    {
      title: '广告名称',
      dataIndex: 'adName',
      key: 'adName',
      width: 100,
    },
    {
      title: '广告ID',
      dataIndex: 'adId',
      key: 'adId',
      width: 100,
    },
    {
      title: '消耗',
      dataIndex: 'cost',
      key: 'cost',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'cost',
      },
    },
    {
      title: '版位信息',
      dataIndex: 'inventoryType',
      key: 'inventoryType',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'inventoryType',
      },
    },
    {
      title: '曝光量',
      dataIndex: 'viewCount',
      key: 'viewCount',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'viewCount',
      },
    },
    {
      title: '点击率',
      dataIndex: 'ctr',
      width: 100,
      key: 'ctr',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'ctr',
      },
    },
    {
      title: '转化率',
      dataIndex: 'conversionsRate',
      key: 'conversionsRate',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'conversionsRate',
      },
    },

    {
      title: '转化成本',
      dataIndex: 'conversionsCost',
      key: 'conversionsCost',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'conversionsCost',
      },
    },
    {
      title: '千次展现均价',
      dataIndex: 'thousandDisplayPrice',
      key: 'thousandDisplayPrice',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'thousandDisplayPrice',
      },
    },
    // {
    //   title: '完播率',
    //   dataIndex: 'videoOuterPlay100Count',
    //   key: 'videoOuterPlay100Count',
    //   width: 100,
    //   sorter: true,
    //   sortDirections: ['descend', 'ascend'],
    //   slots: {
    //     customRender: 'videoOuterPlay100Count',
    //   },
    // },
    // {
    //   title: '广告组状态',
    //   dataIndex: 'invoiceName',
    //   key: 'invoiceName',
    //   slots: {
    //     customRender: 'invoiceName',
    //   },
    // },

    {
      title: '创建时间',
      dataIndex: 'campaignCreateTime',
      key: 'campaignCreateTime',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'campaignCreateTime',
      },
    },
    // {
    //   title: '投放日期',
    //   dataIndex: 'date',
    //   key: 'date',
    //   width: 100,
    //   sorter: true,
    //   sortDirections: ['descend', 'ascend'],
    //   slots: {
    //     customRender: 'date',
    //   },
    // },
    {
      title: '当前运营',
      dataIndex: 'operaterName',
      key: 'operaterName',
      width: 100,
      slots: {
        customRender: 'operaterName',
      },
    },
  ],
  // case '关联广告组其他':
  glggzOther: [
    {
      title: '媒体账号ID',
      dataIndex: 'accountId',
      key: 'accountId',
      width: 100,
    },
    {
      title: '广告组ID',
      dataIndex: 'campaignId',
      key: 'campaignId',
      width: 100,
    },
    // {
    //   title: '广告组状态',
    //   dataIndex: 'invoiceName',
    //   key: 'invoiceName',
    //   slots: {
    //     customRender: 'invoiceName',
    //   },
    // },
    {
      title: '消耗',
      dataIndex: 'cost',
      key: 'cost',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'cost',
      },
    },
    {
      title: '创建日期',
      dataIndex: 'createTime',
      key: 'createTime',
      sorter: true,
      width: 100,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'createTime',
      },
    },
    {
      title: '当前运营',
      dataIndex: 'operaterName',
      key: 'operaterName',
      width: 100,
      slots: {
        customRender: 'operaterName',
      },
    },
  ],
  // 项目
  xm: [
    {
      title: '媒体账号ID',
      dataIndex: 'accountId',
      key: 'accountId',
      width: 100,
    },
   /* {
      title: '项目ID',
      dataIndex: 'projectId',
      key: 'projectId',
      width: 100,
    },*/
    {
      title: '项目名称',
      dataIndex: 'projectName',
      key: 'projectName',
      width: 200,
    },
    {
      title: '消耗',
      dataIndex: 'cost',
      key: 'cost',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'cost',
      },
    },
    {
      title: '当前运营',
      dataIndex: 'operaterName',
      key: 'operaterName',
      width: 100,
      slots: {
        customRender: 'operaterName',
      },
    },
  ],
  // case '关联广告计划':
  glggjh: [
    {
      title: '媒体账号ID',
      dataIndex: 'accountId',
      key: 'accountId',
      width: 100,
    },
    {
      title: '广告名称',
      dataIndex: 'adName',
      key: 'adName',
      width: 100,
    },
    {
      title: '广告计划ID',
      dataIndex: 'adId',
      key: 'adId',
      width: 100,
      slots: {
        customRender: 'adId',
      },
    },
    {
      title: '广告组ID',
      dataIndex: 'campaignId',
      key: 'campaignId',
      width: 100,
      slots: {
        customRender: 'campaignId',
      },
    },
    // {
    //   title: '广告投放状态',
    //   dataIndex: 'cost',
    //   key: 'cost',
    //   sorter: true,
    //   sortDirections: ['descend', 'ascend'],
    //   slots: {
    //     customRender: 'cost',
    //   },
    // },
    {
      title: '消耗',
      dataIndex: 'cost',
      key: 'cost',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'cost',
      },
    },
    // {
    //   title: '版位信息',
    //   dataIndex: 'inventoryType',
    //   key: 'inventoryType',
    //   width: 100,
    //   sorter: true,
    //   sortDirections: ['descend', 'ascend'],
    //   slots: {
    //     customRender: 'inventoryType',
    //   },
    // },
    {
      title: '曝光量',
      dataIndex: 'viewCount',
      key: 'viewCount',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'viewCount',
      },
    },
    {
      title: '点击率',
      dataIndex: 'ctr',
      key: 'ctr',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'ctr',
      },
    },
    {
      title: '转化率',
      dataIndex: 'conversionsRate',
      key: 'conversionsRate',
      width: 100,
    },
    {
      title: '转化成本',
      dataIndex: 'conversionsCost',
      key: 'conversionsCost',
      width: 100,
      slots: {
        customRender: 'conversionsCost',
      },
    },
    {
      title: '千次展现均价',
      dataIndex: 'thousandDisplayPrice',
      key: 'thousandDisplayPrice',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'thousandDisplayPrice',
      },
    },
    {
      title: '创建时间',
      dataIndex: 'campaignCreateTime',
      key: 'campaignCreateTime',
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      slots: {
        customRender: 'campaignCreateTime',
      },
    },
    // {
    //   title: '投放日期',
    //   dataIndex: 'date',
    //   key: 'date',
    //   width: 100,
    //   sorter: true,
    //   sortDirections: ['descend', 'ascend'],
    //   slots: {
    //     customRender: 'date',
    //   },
    // },
    {
      title: '当前运营',
      dataIndex: 'operaterName',
      key: 'operaterName',
      width: 100,
      slots: {
        customRender: 'operaterName',
      },
    },
  ],
  // case '关联计划':
  QCgljh: [
    { title: '媒体账号ID', dataIndex: 'accountId', key: 'accountId', width: 100 },
    { title: '计划名称', dataIndex: 'adName', key: 'adName', width: 100 },
    { title: '计划ID', dataIndex: 'adId', key: 'adId', width: 100 },
    { title: '计划创建时间', dataIndex: 'adCreateTime', key: 'adCreateTime', width: 100 },
    // { title: '计划当前状态', dataIndex: 'status', key: 'status', width: 100 },
    { title: '消耗', dataIndex: 'cost', key: 'cost', width: 100 },
    { title: '展现次数', dataIndex: 'viewCount', key: 'viewCount', width: 100 },
    { title: '平均千次展现', dataIndex: 'thousandDisplayPrice', key: 'thousandDisplayPrice', width: 100 },
    { title: '点击次数', dataIndex: 'click', key: 'click', width: 100 },
    { title: '点击率', dataIndex: 'ctr', key: 'ctr', width: 100 },
    { title: '转化数', dataIndex: 'conversionsCount', key: 'conversionsCount', width: 100 },
    { title: '转化成本', dataIndex: 'conversionsCost', key: 'conversionsCost', width: 100 },
    { title: '转化率', dataIndex: 'conversionsRate', key: 'conversionsRate', width: 100 },
  ],
};
const obj = {
  title: '完播率',
  dataIndex: 'videoOuterPlay100Count',
  key: 'videoOuterPlay100Count',
  width: 100,
  sorter: true,
  sortDirections: ['descend', 'ascend'],
  slots: {
    customRender: 'videoOuterPlay100Count',
  },
};
const getMediaTableColumns = type => {
  if (type === 'VIDEO' && mediaTableColumns.glggzKS.length === 12) {
    mediaTableColumns.glggzKS.splice(9, 0, obj);
  }
  return mediaTableColumns;
};
export const echartsOptions = {
  trend: data => {
    return {
      trigger: 'item',
      legend: { itemHeight: 2, data: data.legend.data, bottom: 0, left: 'center' },
      xAxis: { type: 'category', boundaryGap: 10, axisTick: { show: false } },
      yAxis: [{ axisLabel: { show: true, color: '#999', formatter: value => (value >= 10000 ? value / 10000 + ' 万' : value) } }, { axisLabel: { show: true, color: '#999' } }],
      tooltip: { show: true, trigger: 'axis', axisPointer: { type: 'cross', animation: false } },
      grid: { left: 45, bottom: 45, top: 10, right: 40 },
      series: [
        {
          name: data.series1.name,
          data: data.series1.data,
          type: 'line',
          smooth: true,
          itemStyle: { color: '#197AFB' },
          yAxisIndex: '0',
          symbol: 'circle',
          symbolSize: 0,
          emphasis: {
            color: '#197AFB', //hover拐点颜色定义
            borderWidth: 5, //拐点边框大小
            lineStyle: {
              width: 2, // hover时的折线宽度
            },
          },
        },
        {
          name: data.series2.name,
          data: data.series2.data,
          type: 'line',
          smooth: true,
          itemStyle: { color: '#06C86F' },
          yAxisIndex: '1',
          symbol: 'circle',
          symbolSize: 0,
          emphasis: {
            color: '#06C86F', //hover拐点颜色定义
            borderWidth: 5, //拐点边框大小
            lineStyle: {
              width: 2, // hover时的折线宽度
            },
          },
        },
      ],
    };
  },
  china: data => {
    return {
      backgroundColor: '#FFFFFF',
      tooltip: { trigger: 'item' },
      grid: { left: 0, right: 0, top: 0, bottom: 0 },
      visualMap: {
        show: true,
        x: 'left',
        y: 'bottom',
        splitList: [
          { start: 500, end: 600 },
          { start: 400, end: 500 },
          { start: 300, end: 400 },
          { start: 200, end: 300 },
          { start: 100, end: 200 },
          { start: 0, end: 100 },
        ],
        color: ['#66CC33', '#00FF00', '#66FF33', '#339900', '#33CC00', '#00CC00'],
      },
      series: [
        {
          name: '随机数据',
          type: 'map',
          map: 'china',
          roam: true,
          label: { show: false },
          emphasis: { label: { show: true } },
          data: data.data,
        },
      ],
    };
  },
  province: data => {
    return {
      tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
      grid: { left: 50, right: 10, top: 10, bottom: 30 },
      xAxis: { type: 'value', show: false },
      yAxis: { type: 'category', data: data.yData, axisTick: { show: false }, axisLine: { show: false } },
      series: data.series,
    };
  },
  rate: data => {
    return {
      tooltip: { trigger: 'item' },
      title: { subtext: data.title, left: 'left' },
      legend: { top: 'bottom', right: 'center' },
      series: [
        {
          type: 'pie',
          radius: ['30%', '50%'],
          avoidLabelOverlap: false,
          itemStyle: { borderRadius: 10, borderColor: '#fff', borderWidth: 2 },
          label: { show: false, position: 'center' },
          labelLine: { show: false },
          data: data.seriesData,
        },
      ],
    };
  },
};
export default getMediaTableColumns;
