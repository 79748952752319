<!-- 运营部门组长（组员） -->
<template>
  <div class="yybm_div">
    <div class="showDetail_div">
      <span>显示明细</span>
      <a-switch v-model:checked="showDetail" />
    </div>
    <div class="yybm_box">
      <div class="left">
        <div class="row" style="height: 185px">
          <!-- 消耗 -->
          <div class="box">
            <div class="title">
              <span>消耗</span>
              <p class="text4">刷新倒计时: {{ countdownNum }} 秒</p>
            </div>
            <div class="content" style="justify-content: center">
              <div class="text_description">
                <span class="text4">今日总消耗：{{ formatNumber(dashboardCostData.cost) || 0 }}</span>
              </div>
              <div class="text_description">
                <span class="text5">昨日总消耗：{{ formatNumber(dashboardCostData.yestDayCost) || 0 }}，环比：{{ formatNumber(dashboardCostData.comparedToYesterday) || 0 }}</span>
              </div>
              <div class="text_description">
                <span class="text5">本月总消耗：{{ formatNumber(dashboardCostData.mothCost) || 0 }}</span>
              </div>
            </div>
          </div>
          <!-- 基建 -->
          <div class="box">
            <div class="title">
              <span>基建</span>
            </div>
            <div class="content" style="justify-content: center">
              <div class="text_description">
                <span class="text4">今日基建总数：{{ formatNumber(planData.todayPlanCount) || 0 }}</span>
              </div>
              <div class="text_description">
                <span class="text5">昨日基建数：{{ formatNumber(planData.yesterdayPlanCount) || 0 }}</span>
              </div>
              <div class="text_description">
                <span class="text5">本月基建总数：{{ formatNumber(planData.thisMothPlanCount) || 0 }}</span>
              </div>
            </div>
          </div>
          <!-- 目标 -->
          <div class="box">
            <div class="title">
              <span>目标</span>
              <div class="query" v-if="$store.state?.oauth?.userInfo?.grades == 'GROUPLEADER'">
                <a @click="openSzmbDialog">设置目标</a>
              </div>
            </div>
            <div class="content" style="justify-content: center">
              <div class="text_description">
                <span class="text4">剩余每日需完成：{{ formatNumber(targetCostData.todayTargetCost) || 0 }}</span>
              </div>
              <div class="text_description">
                <span class="text5">今日消耗/目标/进度：{{ formatNumber(targetCostData.todayCost) || 0 }}/{{ formatNumber(targetCostData.todayTargetCost) || 0 }}/{{ formatNumber(targetCostData.todayCompletionRate) || 0 }}</span>
              </div>
              <div class="text_description">
                <span class="text5">本月消耗/目标/进度：{{ formatNumber(targetCostData.monthCost) || 0 }}/{{ formatNumber(targetCostData.monthTargetCost) || 0 }}/{{ formatNumber(targetCostData.monthCompletionRate) || 0 }}</span>
              </div>
              <div class="text_description">
                <span class="text5">本季度消耗/目标/进度：{{ formatNumber(targetCostData.quarterCost) || 0 }}/{{ formatNumber(targetCostData.quarterTargetCost) || 0 }}/{{ formatNumber(targetCostData.quarterCompletionRate) || 0 }}</span>
              </div>
            </div>
            <a-modal v-model:visible="szmbDialog.visible" wrapClassName="szmbDialog" centered :keyboard="false" :maskClosable="false" :footer="null" :width="1100" title="设置目标" @ok="closeSzmbDialog">
              <div class="szmb_div">
                <a-spin :spinning="szmbDialog.loading">
                  <a-tabs v-model:activeKey="szmbDialog.activeKey" @change="szmbActiveChange">
                    <a-tab-pane key="DM" tab="人员目标"></a-tab-pane>
                    <a-tab-pane key="DP" tab="项目目标"></a-tab-pane>
                  </a-tabs>
                  <div class="query">
                    <a-input-search v-model:value="szmbDialog.gjz" placeholder="请输入关键字搜索" style="width: 400px" @search="queryDeptOrTargetList" />
                    <div>
                      <a-button @click="editOrCancel" type="primary">{{ szmbDialog.edit == false ? '编辑' : '取消' }}</a-button>
                      <a-button :disabled="!szmbDialog.edit" type="primary" @click="batchSaveTarget">保存</a-button>
                    </div>
                  </div>
                  <div class="table">
                    <a-table :scroll="{ y: 300, x: '100%' }" :data-source="szmbDialog.dataSource" :columns="szmbDialog.columns">
                      <template #edit="{ record, column }">
                        <a-input-number v-if="szmbDialog.edit" v-model:value="record[column.editValue]" style="width: 100% " :min="1" :max="1000000000" />
                        <span v-else class="whiteSpaceNowrap" :title="formatNumber(record[column.key])">
                          {{ formatNumber(record[column.key]) }}
                        </span>
                      </template>
                      <template #showDetail="{ record, column }">
                        <span class="whiteSpaceNowrap" :title="formatNumber(record[column.key])">
                          {{ formatNumber(record[column.key]) }}
                        </span>
                      </template>
                    </a-table>
                  </div>
                </a-spin>
              </div>
            </a-modal>
          </div>
        </div>
        <div class="row" style="height: 400px">
          <!-- 消耗趋势 -->
          <div class="box">
            <div class="title">
              <span>消耗趋势</span>
              <div class="query">
                <a-checkbox v-model:checked="costTrend.checked" @change="costTrendCheckChange">全选</a-checkbox>
                <a-select
                  ref="select"
                  v-model:value="costTrend.query.type"
                  style="width: 100px"
                  allowClear
                  placeholder="请选择类型"
                  @change="
                    () => {
                      costTrend.checked = true;
                      queryCostTrend();
                    }
                  "
                >
                  <a-select-option v-for="item in costTrend.typeList" :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                </a-select>
                <a-select
                  ref="select"
                  v-model:value="costTrend.query.latitude"
                  style="width: 150px"
                  allowClear
                  placeholder="请选择纬度"
                  @change="
                    () => {
                      costTrend.checked = true;
                      queryCostTrend();
                    }
                  "
                >
                  <a-select-option v-for="item in latitudeList" :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                </a-select>
                <a-range-picker
                  v-model:value="costTrend.time"
                  value-format="YYYY-MM-DD"
                  separator="至"
                  allowClear
                  @change="
                    () => {
                      costTrend.checked = true;
                      queryCostTrend();
                    }
                  "
                >
                </a-range-picker>
              </div>
            </div>
            <div class="content">
              <div class="chart" ref="costTrend_chart"></div>
            </div>
          </div>
        </div>
        <div class="row" style="height: 400px">
          <!-- 产品消耗情况 -->
          <div class="box">
            <div class="title">
              <span>消耗情况</span>
              <div class="query">
                <a-select ref="select" v-model:value="costTeamList.query.latitude" style="width: 150px" allowClear placeholder="请选择纬度" @change="queryCostTeamList">
                  <a-select-option v-for="item in simpleLatitudeList" :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                </a-select>
              </div>
            </div>
            <div class="content">
              <a-table style="width: 100%" :scroll="{ y: 230, x: '100%' }" :data-source="costTeamList.dataSource" :columns="costTeamList.columns">
                <template #showDetail="{ record, column }">
                  <span class="whiteSpaceNowrap" :title="formatNumber(record[column.key])">
                    {{ formatNumber(record[column.key]) }}
                  </span>
                </template>
              </a-table>
            </div>
          </div>
        </div>
        <div class="row" style="height: 400px">
          <!-- 素材使用情况 -->
          <div class="box">
            <div class="title">
              <span>素材使用情况</span>
              <div class="query">
                <a-select v-if="false" ref="select" v-model:value="operatorMaterialUse.query.latitudes" style="width: 230px" :maxTagCount="2" mode="multiple" allowClear placeholder="请选择纬度" @change="queryOperatorMaterialUse">
                  <a-select-option v-for="item in latitudeList" :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
                </a-select>
                <a-range-picker v-model:value="operatorMaterialUse.time" value-format="YYYY-MM-DD" separator="至" allowClear @change="queryOperatorMaterialUse"> </a-range-picker>
              </div>
            </div>
            <div class="content">
              <a-table style="width: 100%" :scroll="{ y: 230, x: '100%' }" :data-source="operatorMaterialUse.dataSource" :columns="operatorMaterialUse.columns">
                <template #showDetail="{ record, column }">
                  <span class="whiteSpaceNowrap" :title="formatNumber(record[column.key])">
                    {{ formatNumber(record[column.key]) }}
                  </span>
                </template>
              </a-table>
            </div>
          </div>
        </div>
        <div class="row" style="height: 400px">
          <!-- 账户消耗情况 -->
          <div class="box" v-if="true">
            <div class="title">
              <span>账户异动情况(暂未开发)</span>
              <div class="query">
                <a-range-picker v-model:value="transferAccount.time" value-format="YYYY-MM-DD" separator="至" allowClear @change="queryTransferAccount"> </a-range-picker>
              </div>
            </div>
            <div class="content">
              <a-table style="width: 100%" :scroll="{ y: 230, x: '100%' }" :data-source="transferAccount.dataSource" :columns="transferAccount.columns">
                <template #showDetail="{ record, column }">
                  <span class="whiteSpaceNowrap" :title="formatNumber(record[column.key])">
                    {{ formatNumber(record[column.key]) }}
                  </span>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="row" style="height: 1005px;">
          <!-- 项目素材TOP10 -->
          <div class="box">
            <div class="title">
              <span>昨日素材TOP10</span>
            </div>
            <div class="content">
              <!-- <div class="chart" ref="materialCostTop_chart"></div> -->
              <Video :materialCostTopData="materialCostTopData" :showDetail="showDetail"></Video>
            </div>
          </div>
        </div>
        <div class="row" style="height: 400px">
          <!-- 各成员消耗占比 -->
          <div class="box">
            <div class="title">
              <span>各成员消耗占比</span>
              <div class="query">
                <a-range-picker v-model:value="uCostProportionChart.time" value-format="YYYY-MM-DD" separator="至" allowClear @change="userCostProportionChart"> </a-range-picker>
              </div>
            </div>
            <div class="content">
              <div class="chart" ref="user_cost_proportion_chart"></div>
            </div>
          </div>
        </div>
        <div class="row" style="height: 400px">
          <!-- 各产品消耗占比 -->
          <div class="box">
            <div class="title">
              <span>各产品消耗占比</span>
              <div class="query">
                <a-range-picker v-model:value="pCostProportionChart.time" value-format="YYYY-MM-DD" separator="至" allowClear @change="projectCostProportionChart"> </a-range-picker>
              </div>
            </div>
            <div class="content">
              <div class="chart" ref="project_cost_proportion_chart"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/axios';
import * as echarts from 'echarts';
import { markRaw } from 'vue';
import moment from 'moment';
import Video from './video.vue';
import { message } from 'ant-design-vue';
export default {
  name: 'Sjbm',
  data() {
    return {
      // 显示明细
      showDetail: false,
      unmountedFlag: true,
      countdownNum: -1,
      // 消耗
      dashboardCostData: {},
      // 基建
      planData: {},
      // 目标
      targetCostData: {},
      // 岗位集合
      jobPositionList: [
        { value: 'PRODUCER', label: '制片' },
        { value: 'SHOOTING', label: '摄像' },
        { value: 'VIDEO', label: '后期' },
        { value: 'SCREENWRITER', label: '编导' },
      ],
      // 维度集合
      latitudeList: [
        { value: 'ALL', label: '按照总消耗统计' },
        { value: 'USER', label: '按照人员统计' },
        { value: 'PROJECT', label: '按照项目统计' },
        // { value: 'TYPE', label: '按照类型统计' },
        // { value: 'CHANNEL', label: '按照渠道统计' },
      ],
      // 维度集合
      simpleLatitudeList: [
        { value: 'USER', label: '按照人员统计' },
        { value: 'PROJECT', label: '按照项目统计' },
        // { value: 'TYPE', label: '按照类型统计' },
        // { value: 'CHANNEL', label: '按照渠道统计' },
      ],
      // 各成员消耗占比
      uCostProportionChart: {
        time: [
          moment()
            .subtract(1, 'months')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        query: {
          endDate: '',
          startDate: '',
        },
        chart: null,
        data: {
          dataset: {
            source: [],
          },
          legend: {
            type: 'scroll',
            orient: 'vertical',
            top: 'middle',
            left: '20',
            itemWidth: 17,
            itemHeight: 12,
            itemStyle: {
              fontSize: 14,
            },
          },
          tooltip: {
            trigger: 'item',
            formatter: params => {
              let back = `${params.name}`;
              const data = params.data;
              back += `<br>
                <span class="echartsName">${params.marker}</span>
                <span class="echartsValue">${this.formatNumber(data[1])}</span>`;
              return back;
            },
          },
          series: [
            {
              type: 'pie',
              left: '240',
              labelLine: { smooth: true },
              clockwise: false,
              cursor: 'auto',
              label: {
                show: false,
              },
              itemStyle: {
                borderColor: '#eee',
                borderWidth: 1,
              },
            },
          ],
        },
      },
      // 各产品消耗占比
      pCostProportionChart: {
        time: [
          moment()
            .subtract(1, 'months')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        query: {
          endDate: '',
          startDate: '',
        },
        chart: null,
        data: {
          dataset: {
            source: [],
          },
          legend: {
            type: 'scroll',
            orient: 'vertical',
            top: 'middle',
            left: '20',
            itemWidth: 17,
            itemHeight: 12,
            itemStyle: {
              fontSize: 14,
            },
          },
          tooltip: {
            trigger: 'item',
            formatter: params => {
              let back = `${params.name}`;
              const data = params.data;
              back += `<br>
                <span class="echartsName">${params.marker}</span>
                <span class="echartsValue">${this.formatNumber(data[1])}</span>`;
              return back;
            },
          },
          series: [
            {
              type: 'pie',
              left: '240',
              labelLine: { smooth: true },
              clockwise: false,
              cursor: 'auto',
              label: {
                show: false,
              },
              itemStyle: {
                borderColor: '#eee',
                borderWidth: 1,
              },
            },
          ],
        },
      },
      // 消耗趋势
      costTrend: {
        checked: true,
        time: [
          moment()
            .subtract(1, 'months')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        // 类型集合
        typeList: [
          { value: 'DATE', label: '分日统计' },
          { value: 'MONTH', label: '分月统计' },
        ],
        query: {
          endDate: '',
          type: 'DATE',
          latitude: 'PROJECT',
          startDate: '',
        },
        chart: null,
        data: {
          dataset: {
            source: [],
          },
          tooltip: {
            formatter: params => {
              if (params?.length) {
                let back = `${params[0].name}<br>`;
                params.forEach(item => {
                  back += `<span class="echartsDiv">
                      <span>${item.marker}${item.seriesName}</span>
                      <span class="echartsValue">${this.formatNumber(item.data[item.seriesIndex + 1])}</span>
                    </span>`;
                });
                return back;
              }
            },
          },
          legend: { top: 5, right: 5, selected: {}, type: 'scroll' },
          grid: { top: '20%' },
          xAxis: { type: 'category' },
          yAxis: { type: 'value' },
          series: [],
        },
      },
      // 产品消耗情况
      costTeamList: {
        query: {
          latitude: 'PROJECT',
        },
        dataSource: [],
        columns: [
          { title: '名称', dataIndex: 'objName', key: 'objName', ellipsis: false, width: 120 },
          { title: '今日消耗', dataIndex: 'todayCost', key: 'todayCost', ellipsis: true, width: 120, slots: { customRender: 'showDetail' } },
          { title: '昨日消耗', dataIndex: 'yesterdayCost', key: 'yesterdayCost', ellipsis: true, width: 110, slots: { customRender: 'showDetail' } },
          { title: '环比昨日', dataIndex: 'comparedToYesterday', key: 'comparedToYesterday', ellipsis: true, width: 80, slots: { customRender: 'showDetail' } },
          { title: '近七日日均消耗', dataIndex: 'avg7DaysCost', key: 'avg7DaysCost', ellipsis: true, width: 120, slots: { customRender: 'showDetail' } },
          { title: '本月消耗', dataIndex: 'thisMonthCost', key: 'thisMonthCost', ellipsis: true, width: 110, slots: { customRender: 'showDetail' } },
          { title: '本月目标', dataIndex: 'thisMonthTargetCost', key: 'thisMonthTargetCost', ellipsis: true, width: 110, slots: { customRender: 'showDetail' } },
          { title: '目标进度', dataIndex: 'thisMonthTargetCostFinishedRate', key: 'thisMonthTargetCostFinishedRate', ellipsis: true, width: 80, slots: { customRender: 'showDetail' } },
          { title: '本季度消耗', dataIndex: 'thisQuarterCost', key: 'thisQuarterCost', ellipsis: true, width: 110, slots: { customRender: 'showDetail' } },
          { title: '昨日消耗账户数', dataIndex: 'yesterdayHaveCostAccCount', key: 'yesterdayHaveCostAccCount', ellipsis: true, width: 120, slots: { customRender: 'showDetail' } },
          { title: '昨日基建', dataIndex: 'yesterdayPlanCount', key: 'yesterdayPlanCount', ellipsis: true, width: 80, slots: { customRender: 'showDetail' } },
          { title: '本月基建', dataIndex: 'monthPlanCount', key: 'monthPlanCount', ellipsis: true, width: 80, slots: { customRender: 'showDetail' } },
          // { title: '运营人员', dataIndex: 'operationsUserName', key: 'operationsUserName' },
        ],
      },
      // 账户消耗情况
      transferAccount: {
        time: [
          moment()
            .subtract(1, 'months')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        query: {
          endDate: '',
          operatorIds: [],
          projectIds: [],
          startDate: '',
        },
        dataSource: [],
        columns: [
          { title: '账户名称', dataIndex: '', key: '', ellipsis: true },
          { title: '客户名称', dataIndex: '', key: '', ellipsis: true },
          { title: '产品名称', dataIndex: '', key: '', ellipsis: true },
          { title: '今日消耗', dataIndex: '', key: '', ellipsis: true, slots: { customRender: 'showDetail' } },
          { title: '近七日日均消耗', dataIndex: '', key: '', ellipsis: true, slots: { customRender: 'showDetail' } },
          { title: '本月消耗', dataIndex: '', key: '', ellipsis: true, slots: { customRender: 'showDetail' } },
          { title: '本季度消耗', dataIndex: '', key: '', ellipsis: true, slots: { customRender: 'showDetail' } },
          { title: '本月基建数', dataIndex: '', key: '', ellipsis: true, slots: { customRender: 'showDetail' } },
          { title: '运营人员', dataIndex: '', key: '', ellipsis: true },
        ],
      },
      // 素材使用情况
      operatorMaterialUse: {
        time: [
          moment()
            .subtract(1, 'months')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        query: {
          endDate: '',
          latitudes: [],
          startDate: '',
        },
        dataSource: [],
        columns: [
          { title: '产品名称', dataIndex: 'ofProjectName', key: 'ofProjectName', width: 100, ellipsis: true },
          { title: '消耗', dataIndex: 'cost', key: 'cost', width: 70, ellipsis: true, slots: { customRender: 'showDetail' } },
          { title: '制作数', dataIndex: 'makeCount', key: 'makeCount', width: 70, ellipsis: true, slots: { customRender: 'showDetail' } },
          { title: '使用数量', dataIndex: 'usingCount', key: 'usingCount', width: 100, ellipsis: true, slots: { customRender: 'showDetail' } },
          { title: '使用率', dataIndex: 'usingRate', key: 'usingRate', width: 70, ellipsis: true },
          { title: '有效数', dataIndex: 'effectiveCount', key: 'effectiveCount', width: 70, ellipsis: true, slots: { customRender: 'showDetail' } },
          { title: '有效率', dataIndex: 'effectiveRate', key: 'effectiveRate', width: 70, ellipsis: true },
          { title: '首发数', dataIndex: 'firstCount', key: 'firstCount', width: 70, ellipsis: true, slots: { customRender: 'showDetail' } },
          { title: '首发率', dataIndex: 'firstRate', key: 'firstRate', width: 70, ellipsis: true },
          { title: '优质数', dataIndex: 'higtCount', key: 'higtCount', width: 70, ellipsis: true, slots: { customRender: 'showDetail' } },
          { title: '优质率', dataIndex: 'higtRate', key: 'higtRate', width: 70, ellipsis: true },
          // { title: '查询范围', dataIndex: 'dateRange', key: 'dateRange', ellipsis: true },
          // { title: '近7日上新素材使用率', dataIndex: '', key: '', ellipsis: true },
          // { title: '近7日素材有效率(>500消耗)', dataIndex: '', key: '', ellipsis: true },
        ],
      },
      // 项目素材TOP10
      materialCostTop: {
        time: [],
        query: {
          endDate: '',
          operatorIds: [],
          projectIds: [],
          startDate: '',
        },
        chart: null,
        data: {
          dataset: {
            source: [],
          },
          legend: { show: false },
          grid: { top: '3%', bottom: '-4%' },
          xAxis: { type: 'value', show: false },
          yAxis: { type: 'category', inverse: true },
          series: [
            {
              type: 'bar',
              barMaxWidth: 16,
              showBackground: true,
              backgroundStyle: {
                color: '#eaeef1',
              },
              itemStyle: { color: '#3ba1ff', emphasis: { color: '#0a75d7' } },
              label: { show: true, position: 'insideLeft', color: '#fff' },
            },
          ],
        },
      },
      materialCostTopData: [],
      // 设置目标弹框
      szmbDialog: {
        visible: false,
        activeKey: 'DM',
        gjz: '',
        loading: false,
        edit: false,
        dataSource: [],
        editData: [],
        columns: [
          { title: 'ID', dataIndex: 'objPk', key: 'objPk' },
          { title: this.szmbDialogTableHeadName, dataIndex: 'objName', key: 'objName' },
          { title: '本月消耗', dataIndex: 'thisMothCost', key: 'thisMothCost', slots: { customRender: 'showDetail' } },
          { title: '本月目标', dataIndex: 'thisMothTargetCost', key: 'thisMothTargetCost', editValue: 'bymb', with: '220px', slots: { customRender: 'edit' } },
          { title: '上月消耗', dataIndex: 'lastMothCost', key: 'lastMothCost', slots: { customRender: 'showDetail' } },
          { title: '上月目标', dataIndex: 'lastMothTargetCost', key: 'lastMothTargetCost', slots: { customRender: 'showDetail' } },
          { title: '本季度消耗', dataIndex: 'thisQuarterCost', key: 'thisQuarterCost', slots: { customRender: 'showDetail' } },
          { title: '本季度目标', dataIndex: 'thisQuarterTargetCost', key: 'thisQuarterTargetCost', editValue: 'bjdmb', with: '220px', slots: { customRender: 'edit' } },
        ],
      },
    };
  },
  components: { Video },
  props: {},
  watch: {},
  computed: {},
  created() {
    this.getDashboardCost();
    // 开始长轮询
    this.longPolling();
  },
  mounted() {
    // 初始化
    this.init();
    // 监听界面宽度变化
    window.addEventListener('resize', this.resizeWindow);
  },
  unmounted() {
    this.unmountedFlag = false;
    // 销毁监听
    window.removeEventListener('resize', this.resizeWindow);
  },
  methods: {
    // 初始化
    init() {
      // 查询实时消耗-运营-设计 团队以及个人
      // this.getDashboardCost();
      // 查询基建情况-运营团队或个人
      this.getPlan();
      // 目标跟踪-运营-设计 团队以及个人
      this.getTargetCost();
      // 消耗趋势-运营岗位分日、分月消耗趋势
      this.queryCostTrend();
      // 我的小组运营产品本月-本季度消耗情况。产品、人员消耗占比复用该接口
      this.queryCostTeamList();
      // 运营异常账户动向
      this.queryTransferAccount();
      // 运营素材使用情况
      this.queryOperatorMaterialUse();
      // 运营素材TOP10-默认昨日
      this.queryMaterialCostTop();
      //各成员消耗占比
      this.userCostProportionChart();
      //各产品消耗占比
      this.projectCostProportionChart();
    },
    szmbDialogTableHeadName() {
      return this.szmbDialog.activeKey == '1' ? '人员名称' : '项目名称';
    },
    formatNumber(num) {
      if (!num) return num;
      if (this.showDetail) return num;
      num = Number(num);
      if (num == 0 || (num > 0 && num < 10000)) {
        return num + '';
      } else {
        return (num / 10000).toFixed(2) + '万';
      }
    },
    async sleepTime() {
      for (let i = 0; i < 15; i++) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        this.countdownNum--;
      }
    },
    async longPolling() {
      if (this.countdownNum < 0) {
        this.countdownNum = 15;
        while (this.countdownNum >= 0 && this.unmountedFlag) {
          this.countdownNum = 15;
          // alert("向服务器发送长轮询请求！");
          this.getTargetCost();
          const res = await axios.get('/home/dashboard/cost');
          await this.sleepTime();
          // alert(res.data);
          this.dashboardCostData = res.data || {};
        }
      }
    },
    // 查询实时消耗-运营-设计 团队以及个人
    getDashboardCost() {
      axios.get('/home/dashboard/cost').then(res => {
        this.dashboardCostData = res.data || {};
      });
    },
    // 查询基建情况-运营团队或个人
    getPlan() {
      axios.get('/home/dashboard/plan').then(res => {
        this.planData = res.data || {};
      });
    },
    // 目标跟踪-运营-设计 团队以及个人
    getTargetCost() {
      axios.get('/home/target/cost').then(res => {
        this.targetCostData = res.data || {};
      });
    },
    // 消耗趋势-运营岗位分日、分月消耗趋势
    queryCostTrend() {
      axios
        .post('/dashboard/cost_trend', {
          ...this.costTrend.query,
          startDate: this.costTrend.time[0] || '',
          endDate: this.costTrend.time[1] || '',
        })
        .then(res => {
          this.costTrend.data.dataset.source = [];
          this.costTrend.data.series = [];
          this.costTrend.data.legend.selected = {};
          // 组装二维数组
          const Source = [['']];
          res.data.forEach(item => {
            // 计算有几个Y轴
            if (!Source[0].includes(item.objName)) {
              Source[0].push(item.objName);
              this.costTrend.data.series.push({ type: 'line', showSymbol: false, smooth: true });
              // 默认显示所有
              this.costTrend.data.legend.selected[item.objName] = true;
            }
            // 计算有几个X轴
            let find = false;
            Source.forEach(list => {
              if (list[0] === item.dateX) {
                find = true;
              }
            });
            // 如果没找到
            if (!find) {
              Source.push([item.dateX]);
            }
          });
          // 循环写入数据
          for (let i = 1; i < Source.length; i++) {
            const arr = Source[i];
            Source[0].forEach((y, index) => {
              if (index !== 0) {
                const isFind = res.data.find(data => arr[0] === data.dateX && y === data.objName);
                arr[index] = isFind ? isFind.cost : 0;
              }
            });
          }
          // 图表数据源赋值
          this.costTrend.data.dataset.source = Source;
          if (this.costTrend.chart) {
            // 如果存在，销毁图表
            this.costTrend.chart.dispose();
          }
          this.$nextTick(() => {
            this.costTrend.chart = markRaw(echarts.init(this.$refs.costTrend_chart));
            const option = this.echartsInit(this.costTrend.data);
            this.costTrend.chart.setOption(option);
          });
        });
    },
    // 全选、反选
    costTrendCheckChange() {
      for (const key in this.costTrend.data.legend.selected) {
        // const item = this.costTrend.data.legend.selected[key];
        this.costTrend.data.legend.selected[key] = this.costTrend.checked;
      }
      const option = this.echartsInit(this.costTrend.data);
      this.costTrend.chart.setOption(option);
    },
    // 我的小组运营产品本月-本季度消耗情况。产品、人员消耗占比复用该接口
    queryCostTeamList() {
      axios
        .post('/dashboard/cost_team_list', {
          ...this.costTeamList.query,
        })
        .then(res => {
          this.costTeamList.dataSource = res.data;
        });
    },
    // 运营素材TOP10-默认昨日
    queryMaterialCostTop() {
      axios
        .post('/dashboard/material_cost_top', {
          ...this.materialCostTop.query,
        })
        .then(res => {
          this.materialCostTopData = res.data;
          // this.materialCostTop.data.dataset.source = [];
          // // 组装二维数组
          // const Source = [['', '']];
          // res.data.forEach(item => {
          //   Source.push([item.materialName, item.cost]);
          // });
          // // 图表数据源赋值
          // this.materialCostTop.data.dataset.source = Source;
          // if (this.materialCostTop.chart) {
          //   // 如果存在，销毁图表
          //   this.materialCostTop.chart.dispose();
          // }
          // this.$nextTick(() => {
          //   this.materialCostTop.chart = markRaw(echarts.init(this.$refs.materialCostTop_chart));
          //   const option = this.echartsInit(this.materialCostTop.data);
          //   this.materialCostTop.chart.setOption(option);
          // });
        });
    },
    echartsInit(dataSource) {
      let xAxis = {
        type: 'category',
        axisTick: { show: false },
        axisLine: { show: false },
      };
      if (dataSource.xAxis) {
        if (Object.prototype.toString.call(dataSource.xAxis) === '[object Object]') {
          xAxis = { ...xAxis, ...dataSource.xAxis };
        } else if (Object.prototype.toString.call(dataSource.xAxis) === '[object Array]') {
          xAxis = dataSource.xAxis.map(item => {
            return {
              axisTick: { show: false },
              axisLine: { show: false },
              ...item,
            };
          });
        }
      }
      let yAxis = {
        type: 'value',
        axisTick: { show: false },
        axisLine: { show: false },
      };
      if (dataSource.yAxis) {
        if (Object.prototype.toString.call(dataSource.yAxis) === '[object Object]') {
          yAxis = { ...yAxis, ...dataSource.yAxis };
        } else if (Object.prototype.toString.call(dataSource.yAxis) === '[object Array]') {
          yAxis = dataSource.yAxis.map(item => {
            return {
              axisTick: { show: false },
              axisLine: { show: false },
              ...item,
            };
          });
        }
      }
      const options = {
        ...dataSource,
        legend: { ...dataSource.legend },
        tooltip: {
          trigger: 'axis',
          ...dataSource.tooltip,
        },
        grid: {
          top: '7%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
          ...dataSource.grid,
        },
        xAxis: xAxis,
        yAxis: yAxis,
      };
      return options;
    },
    resizeWindow() {
      if (this.costTrend.chart) this.costTrend.chart.resize();
      if (this.uCostProportionChart.chart) this.uCostProportionChart.chart.resize();
      if (this.pCostProportionChart.chart) this.pCostProportionChart.chart.resize();
    },
    // 运营异常账户动向
    queryTransferAccount() {
      axios
        .post('/dashboard/transfer_account', {
          ...this.transferAccount.query,
          startDate: this.transferAccount.time[0] || '',
          endDate: this.transferAccount.time[1] || '',
        })
        .then(res => {
          this.transferAccount.dataSource = res.data;
        });
    },
    // 运营素材使用情况
    queryOperatorMaterialUse() {
      axios
        .post('/dashboard/operator_material_use', {
          ...this.operatorMaterialUse.query,
          startDate: this.operatorMaterialUse.time[0] || '',
          endDate: this.operatorMaterialUse.time[1] || '',
        })
        .then(res => {
          this.operatorMaterialUse.dataSource = res.data;
        });
    },
    //各成员消耗占比
    userCostProportionChart() {
      axios
        .post('/dashboard/cost_proportion_chart', {
          latitude: 'USER',
          startDate: this.uCostProportionChart.time[0] || '',
          endDate: this.uCostProportionChart.time[1] || '',
        })
        .then(res => {
          this.uCostProportionChart.data.dataset.source = [];
          // 组装二维数组
          const Source = [['', '']];
          res.data.forEach(item => {
            // 计算有几个X轴
            let find = false;
            Source.forEach(list => {
              if (list[0] === item.objName) {
                find = true;
              }
            });
            // 如果没找到
            if (!find) {
              Source.push([item.objName, item.cost]);
            }
          });
          // 计算总数
          let allValue = 0;
          for (let i = 1; i < Source.length; i++) {
            const item = Source[i];
            allValue += item[1];
          }
          // 图例 展示百分比
          this.uCostProportionChart.data.legend.formatter = name => {
            let value = 0;
            for (let i = 1; i < Source.length; i++) {
              const item = Source[i];
              if (item[0]) {
                if (item[0] === name) {
                  value = item[1];
                }
              } else if (!!item[0] === !!name) {
                value = item[1];
              }
            }
            return `${name}（${((value / allValue).toFixed(4) * 100).toFixed(2)}%）`;
          };
          // 图表数据源赋值
          this.uCostProportionChart.data.dataset.source = Source;
          if (this.uCostProportionChart.chart) {
            // 如果存在，销毁图表
            this.uCostProportionChart.chart.dispose();
          }
          this.$nextTick(() => {
            this.uCostProportionChart.chart = markRaw(echarts.init(this.$refs.user_cost_proportion_chart));
            const option = this.echartsInit(this.uCostProportionChart.data);
            this.uCostProportionChart.chart.setOption(option);
          });
        });
    },
    //各产品消耗占比
    projectCostProportionChart() {
      axios
        .post('/dashboard/cost_proportion_chart', {
          latitude: 'PROJECT',
          startDate: this.pCostProportionChart.time[0] || '',
          endDate: this.pCostProportionChart.time[1] || '',
        })
        .then(res => {
          this.pCostProportionChart.data.dataset.source = [];
          // 组装二维数组
          const Source = [['', '']];
          res.data.forEach(item => {
            // 计算有几个X轴
            let find = false;
            Source.forEach(list => {
              if (list[0] === item.objName) {
                find = true;
              }
            });
            // 如果没找到
            if (!find) {
              Source.push([item.objName, item.cost]);
            }
          });
          // 计算总数
          let allValue = 0;
          for (let i = 1; i < Source.length; i++) {
            const item = Source[i];
            allValue += item[1];
          }
          // 图例 展示百分比
          this.pCostProportionChart.data.legend.formatter = name => {
            let value = 0;
            for (let i = 1; i < Source.length; i++) {
              const item = Source[i];
              if (item[0]) {
                if (item[0] === name) {
                  value = item[1];
                }
              } else if (!!item[0] === !!name) {
                value = item[1];
              }
            }
            return `${name}（${((value / allValue).toFixed(4) * 100).toFixed(2)}%）`;
          };
          // 图表数据源赋值
          this.pCostProportionChart.data.dataset.source = Source;
          if (this.pCostProportionChart.chart) {
            // 如果存在，销毁图表
            this.pCostProportionChart.chart.dispose();
          }
          this.$nextTick(() => {
            this.pCostProportionChart.chart = markRaw(echarts.init(this.$refs.project_cost_proportion_chart));
            const option = this.echartsInit(this.pCostProportionChart.data);
            this.pCostProportionChart.chart.setOption(option);
          });
        });
    },
    // 打开设置目标弹框
    openSzmbDialog() {
      this.szmbDialog.visible = true;
      this.szmbDialog.edit = false;
      this.szmbDialog.activeKey = 'DM';
      // 查询部门产品OR部门成员目标
      this.queryDeptOrTargetList();
    },
    // 关闭设置目标弹框
    closeSzmbDialog() {
      this.szmbDialog.visible = false;
    },
    // 设置目标弹框tab页切换
    szmbActiveChange() {
      // 查询部门产品OR部门成员目标
      this.queryDeptOrTargetList();
      this.szmbDialog.edit = false;
    },
    // 编辑/取消
    editOrCancel() {
      this.szmbDialog.edit = !this.szmbDialog.edit;
      // 取消
      if (!this.szmbDialog.edit) {
        this.szmbDialog.dataSource.forEach(item => {
          if (item.bymb) item.bymb = item.thisMothTargetCost;
          if (item.bjdmb) item.bjdmb = item.thisQuarterTargetCost;
        });
      }
    },
    // 查询部门产品OR部门成员目标
    queryDeptOrTargetList() {
      this.szmbDialog.loading = true;
      axios
        .post('/cost_target/dept_target_list', {
          objName: this.szmbDialog.gjz,
          objType: this.szmbDialog.activeKey, // DP:部门项目、DM：部门成员
        })
        .then(res => {
          this.szmbDialog.dataSource = res.data?.map(item => {
            return {
              ...item,
              bymb: item.thisMothTargetCost, // 本月目标
              bjdmb: item.thisQuarterTargetCost, // 本季度目标
            };
          });
        })
        .finally(() => {
          this.szmbDialog.loading = false;
        });
    },
    // 查询部门产品OR部门成员目标
    batchSaveTarget() {
      this.szmbDialog.loading = true;
      const arr = this.szmbDialog.dataSource.map(item => {
        return {
          objName: item.objName,
          objPk: item.objPk,
          objType: this.szmbDialog.activeKey,
          thisMothTargetCost: this.szmbDialog.edit ? item.bymb : item.thisMothTargetCost,
          thisQuarterTargetCost: this.szmbDialog.edit ? item.bjdmb : item.thisQuarterTargetCost,
        };
      });
      axios
        .post('/cost_target/batch_save_target', arr)
        .then(res => {
          if (res.code === 0) {
            message.success('保存成功');
            // 关闭设置目标弹框
            this.closeSzmbDialog();
            // 初始化
            this.init();
          }
        })
        .finally(() => {
          this.szmbDialog.loading = false;
        });
    },
  },
};
</script>
<style lang="less">
.szmbDialog {
  .ant-modal-body {
    padding: 10px 24px;
  }
}
.echartsDiv {
  display: flex;
  justify-content: space-between;
  .echartsValue {
    margin-left: 20px;
  }
}
.szmb_div {
  .query {
    display: flex;
    justify-content: space-between;
    > div {
      .ant-btn + .ant-btn {
        margin-left: 10px;
      }
    }
  }
  .table {
    margin-top: 10px;
  }
}
</style>
<style lang="less" scoped>
.whiteSpaceNowrap {
  white-space: nowrap;
}
.yybm_div {
  width: 100%;
  .yybm_box {
    display: flex;
  }
  .showDetail_div {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    > span {
      margin-right: 10px;
      font-size: 12px;
    }
  }
  .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 65%;
  }
  .right {
    width: 35%;
    flex-shrink: 0;
    display: flex;
    margin-left: 12px;
    flex-direction: column;
  }
  .row {
    display: flex;
    & + .row {
      margin-top: 10px;
    }
    .box {
      display: flex;
      flex-direction: column;
      flex: 1;
      background: #fff;
      padding: 12px;
      flex-shrink: 0;
      width: 100%;
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        .query {
          font-size: 12px;
          * + * {
            margin-left: 5px;
          }
          .ant-calendar-picker {
            width: 185px;
          }
        }
        > span::before {
          content: '';
          display: inline-block;
          height: 12px;
          width: 4px;
          background-color: rgb(47, 112, 244);
          margin-right: 10px;
          transform: translateY(1px);
        }
      }
      & + .box {
        margin-left: 12px;
      }
    }
    .content {
      display: flex;
      align-items: center;
      flex-direction: column;
      flex: 1;
      .chart {
        width: 100%;
        height: 100%;
      }
    }
    .text_description {
      // flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & + .text_description {
        margin-top: 10px;
      }
      .consume_detail {
        margin-top: 10px;
      }
      .status_icon {
        .anticon-caret-up {
          color: rgba(19, 202, 92, 1);
        }
        .anticon-caret-down {
          color: rgba(215, 12, 12, 1);
        }
      }
      .text1 {
        font-size: 14px;
        color: #666666;
      }
      .text2 {
        font-size: 30px;
        font-weight: bold;
      }
      .text3 {
        font-size: 12px;
        color: #666666;
      }
      .text4 {
        font-weight: bold;
        color: rgba(0, 0, 0, 1);
        font-size: 20px;
        white-space: nowrap;
      }
      .text5 {
        color: rgba(0, 0, 0, 1);
        font-size: 12px;
        white-space: nowrap;
      }
    }
  }
}
</style>
