<!--
 * @Author: LiuXin
 * @Date: 2021-09-08 15:41:46
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-10-21 14:04:03
-->
<template>
  <div class="w100 h100">
    <!-- 模块顶部 -->
    <p class="module_title">
      <span>
        <span class="title_content"> 实时概览</span>
        <a>最新统计时间：{{ overView.date || '--' }} </a>
      </span>
      <span>
        <a-select @change="jobChange" class="mr10" style="width: 120px" :value="$store.state.globalEvent.args.job" :options="jobList"> </a-select>
      </span>
    </p>
    <a-spin :spinning="spinLoading">
      <div class="over_view w100 h100">
        <!-- 今日消耗 -->
        <div class="today_consume over_view_item h100">
          <div class="text_description">
            <p class="text3">今日消耗</p>
            <p class="consume_detail">
              <span class="text2">{{ withComma(overView.costInfo.cost) }}</span>
              <span class="text3">较昨天 {{ withComma(overView.costInfo.yestDayCost) }}</span>
            </p>
          </div>
        </div>
        <!-- 今日上新数 -->
        <div class="today_upload over_view_item h100">
          <div class="text_description">
            <p class="text3">今日上新数</p>
            <div class="upload_detail">
              <div class="current">
                <span class="text3">视频</span> <span class="text2">{{ overView.todayInfo.video }}</span> <span class="text3">图片</span> <span class="text2">{{ overView.todayInfo.image }}</span> <span class="text3">脚本</span> <span class="text2">{{ overView.todayInfo.make }}</span>
              </div>
              <div class="last">
                <span class="text3">较昨日</span>
                <span class="text3">视频 {{ overView.todayInfo.yestDayVideo }}</span> <span class="text3">图片 {{ overView.todayInfo.yestDayImage }}</span> <span class="text3">脚本 {{ overView.todayInfo.yestDayMake }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 月度消耗 -->
        <div class="month_consume over_view_item h100">
          <div class="text_description">
            <p class="text3">月度消耗</p>
            <p class="consume_detail">
              <span class="text2">{{ withComma(overView.monthCostInfo.cost) }}</span>
              <span class="text3">较上月 {{ toPercent(overView.monthCostInfo.rate) }}</span>
              <span class="status_icon">
                <CaretUpFilled v-if="overView.monthCostInfo.rate > 0" />
                <CaretDownFilled v-else />
              </span>
            </p>
          </div>
        </div>
        <!-- 本月上新数 -->
        <div class="month_upload over_view_item h100">
          <div class="text_description">
            <p class="text3">本月上新数</p>
            <div class="upload_detail">
              <div class="current">
                <span class="text3">视频</span> <span class="text2">{{ overView.monthInfo.video }}</span> <span class="text3">图片</span> <span class="text2">{{ overView.monthInfo.image }}</span> <span class="text3">脚本</span> <span class="text2">{{ overView.monthInfo.make }}</span>
              </div>
              <div class="last">
                <span class="text3">较上月</span>
                <span class="text3">视频 {{ overView.monthInfo.yestMonthVideo }}</span> <span class="text3">图片 {{ overView.monthInfo.yestMonthImage }}</span> <span class="text3">脚本 {{ overView.monthInfo.yestMonthMake }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { CaretUpFilled, CaretDownFilled } from '@ant-design/icons-vue';
import { getOverview } from '@/api/dashboard/designPerson.js';
import moment from 'moment';
export default {
  name: 'OperatorOverView',
  props: { echartsOptions: { type: Object, default: () => {} } },
  components: { CaretUpFilled, CaretDownFilled },
  data() {
    return {
      spinLoading: true,
      overView: {
        date: null,
        costInfo: {},
        todayInfo: {},
        monthCostInfo: {},
        monthInfo: {},
      },
      jobList: [
        { value: '', label: '所有' },
        { value: 'SCENARIST', label: '编导' },
        { value: 'FILEMAKER', label: '制片' },
        { value: 'PHOTOGRAPHER', label: '拍摄' },
        { value: 'POSTER', label: '后期' },
      ],
    };
  },
  computed: {
    // 数字用逗号隔开
    withComma() {
      return num => (isNaN(parseFloat(num)) ? '-' : parseFloat(num).toLocaleString());
    },
    // 数字转百分比
    toPercent() {
      return num => {
        num = num || 0;
        return (num * 100).toFixed(2) + '%';
      };
    },
  },
  async created() {
    await this.getData();
  },

  methods: {
    // 获取实时概览数据
    async getData() {
      this.spinLoading = true;
      const params = { currDate: moment().format('YYYY-MM-DD') };
      const res = await getOverview(params);
      this.overView = res?.data || this.overView;
      this.spinLoading = false;
    },
    // 岗位变化
    jobChange(val) {
      const obj = { event: 'jobChange', args: { job: val } };
      this.$store.commit('SET_GLOBALEVENT', obj);
    },
  },
};
</script>

<style scoped lang="less">
.over_view_item {
  width: 25%;
  .text3 {
    margin-left: 20px;
  }
  .upload_detail {
    .current {
      padding-top: 15px;
    }
    .last {
      padding-top: 10px;
    }
  }
  .consume_detail {
    padding: 20px 0 0 20px;
  }
}
</style>
