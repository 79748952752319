/*
 * @Author: chenxing
 * @Date: 2021-04-13 09:49:34
 * @LastEditors: Yran
 * @LastEditTime: 2022-02-10 17:42:34
 */
import axios, { CancelToken, lydata_prefix } from '@/utils/axios';

const apiUrl = {
  materialList: '/material/materialMakeInfoRelation/getList',
  materialPage: '/material/materialMakeInfoRelation/getPage',
  materialSaveUpdate: '/material/materialMakeInfoRelation/save',
  materialDataById: '/material/materialMakeInfoRelation/getById',
  materialMakeListByName: '/material/materialMake/getByMakeName',
  deleteMaterialId: '/material/materialMakeInfoRelation/delete',
  getUserList: 'sys/user/list',
  materialPersonInfo: '/material/materialMakeInfoRelation/getMaterialPersonInfo',
  updateLargeProduct: '/material/materialMakeInfoRelation/updateBatchProject',
  exportExcel: '/material/materialMakeInfoRelation/export',
  queryMediaAccountId: '/material/materialMakeInfoRelation/getMaterialIdList',
  getAllDirector: 'sys/user/getListByJobs',
  getAssociateDetailedData: '/material/materialMakeInfoRelation/getAssociateDetailedData',
  checkMD5: '/material/materialMakeInfoRelation/getMd5Repeat',
  getAllTemp: '/temp/tempCustomConfig/getByConfigKey/MATERIAL',
  getChooseTemp: '/temp/tempUserCustom/getTempNames/',
  getTempName: '/temp/tempUserCustom/getConfigTypeAndTempNames/MATERIAL',
  getCostByMd5: '/material/materialMakeInfoRelation/getCostByMd5',
  updateMaterial: '/material/materialMakeInfoRelation/update',
  batchPushAccountList: '/task/get/account/push/list',
  batchPushToAccount: '/task/push/material/to/account',
  getMaterialNameList: '/material/materialMakeInfoRelation/material',
  getMaterialTotal: '/material/materialMakeInfoRelation/getStatistics',
  getMaterialQuota: '/material/materialMakeInfoRelation/quota',
  updateByFileName: '/material/materialMakeInfoRelation/updateByFileName',
  getMaterialShootingId: '/material/materialMakeInfoRelation/getMaterialShootingId/',
  getMakeCodeList: '/material/materialMake/condition/get',
  getTrendData: '/lydata/material/materialMakeInfoRelation/trend/getList',
  getMaterialReviewDetail: '/lydata/material/materialMakeInfoRelation/getMaterialReviewDetail',
  getMaterialLibraryLabelAnd: '/lydata/material/materialMakeInfoRelation/getMaterialLibraryLabelAnd',
  getMaterialLibraryLabel: '/lydata/material/materialMakeInfoRelation/getMaterialLibraryLabel',
  getLabelInfo: '/material/materialMakeInfoRelation/getLabelInfo/',
  videoFileToAgentServerUploadUrl: '/extractor/tt/video/to/agent',
  videoFileToAgentClientUploadUrl: '/oceanengine/tt/file/video/agent/',
};
// 获取素材详情关联趋势数据
export function getTrendData(params) {
  return axios.post(apiUrl.getTrendData, params);
}
// 获取素材详情标签
export function getLabelInfo(id) {
  return axios.get(apiUrl.getLabelInfo + id);
}
//获取部门
export function getMaterialListData(params) {
  return axios.post(apiUrl.materialList, params);
}

//获取素材(有分页)
export function getMaterialPageData(params) {
  return axios.post(lydata_prefix + apiUrl.materialPage, params);
}

// 更新或保存素材
export function saveUpdateMaterialData(params) {
  return axios.post(apiUrl.materialSaveUpdate, params);
}

// 根据id查看素材信息关联
export function getMaterialDataById(id) {
  return axios.get(apiUrl.materialDataById + `/${id}`);
}

// 根据id查看素材信息关联
export function getMakeListDataByName(params) {
  return axios.post(apiUrl.materialMakeListByName, params);
}

// 根据id删除素材信息
export function deleteMaterialById(params) {
  return axios.post(apiUrl.deleteMaterialId, params);
}

//查询所有的上传人员
export function getUserListData(params) {
  return axios.post(apiUrl.getUserList, params);
}

//批量修改素材的产品
export function updateLargeProductData(params) {
  return axios.post(apiUrl.updateLargeProduct, params);
}

//导出素材报表
export function exportExcel(params) {
  return axios.post(lydata_prefix + apiUrl.exportExcel, params, {
    timeout: 30 * 1000,
    withCredentials: true,
    responseType: 'blob',
  });
}

// 获取素材人员信息
export function getMaterialPersonInfoData(mid) {
  return axios.get(apiUrl.materialPersonInfo + `/${mid}`);
}

// 模糊查询媒体账号Id
export function queryMediaAccountId(id) {
  return axios.get(apiUrl.queryMediaAccountId + `/${id}`);
}

//获取所有的编导
export function getAllDirectorData(params) {
  return axios.post(apiUrl.getAllDirector, params);
}

// export const getAssociateDetailedDataSource = CancelToken.source();
export let getAssociateDetailedDataCancel;
export function getAssociateDetailedData(params) {
  return axios.post(lydata_prefix + apiUrl.getAssociateDetailedData, params, {
    cancelToken: new CancelToken(function executor(c) {
      // executor 函数接收一个 cancel 函数作为参数
      getAssociateDetailedDataCancel = c;
    }),
  });
}

// 检查MD5是否存在数据库里
export function checkMD5(param) {
  return axios.post(apiUrl.checkMD5, param);
}
export function videoFileToAgent(fileName,md5,file,agentId,projectName,client) {
  if (client) {
    const clientFormData = new FormData();
    clientFormData.append('agent_id', agentId);
    clientFormData.append('upload_type', "UPLOAD_BY_FILE");
    clientFormData.append('video_signature', md5);
    clientFormData.append('file_name', fileName);
    clientFormData.append('is_aigc', false);
    clientFormData.append('is_need_auth', true);
    clientFormData.append("video_file",file);
    axios.post(apiUrl.videoFileToAgentClientUploadUrl, clientFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Token': '6f7812b397da07072485d839faacc83bf40503fe',
      }
    }).then(response => {
      console.log(response);
    })
      .catch(error => {
        console.error(error);
      });
  }else{
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);
    formData.append('md5', md5);
    formData.append('protectedPort', agentId);
    formData.append('projectName', projectName);
    axios.post(apiUrl.videoFileToAgentServerUploadUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
}

// 获取所有素材库指标
export function getAllTemp() {
  return axios.get(apiUrl.getAllTemp);
}

// 获取素材库自定义模板名称
export function getTempName() {
  return axios.get(apiUrl.getTempName);
}

// 获取选中的素材库指标
export function getChooseTemp(tempName) {
  return axios.get(apiUrl.getChooseTemp + `${tempName}`);
}

// 获取选中的素材库指标
export function getCostByMd5(params) {
  return axios.post(lydata_prefix + apiUrl.getCostByMd5, params);
}
// 素材详情修改素材
export function updateMaterial(params) {
  return axios.post(apiUrl.updateMaterial, params);
}

// 获取选中的素材库指标
export function batchPushAccountList(params) {
  return axios.post(apiUrl.batchPushAccountList, params);
}

// 获取选中的素材库指标
export function batchPushToAccount(params) {
  return axios.post(apiUrl.batchPushToAccount, params);
}
// 获取素材库列表总计
export function getMaterialTotal(params) {
  return axios.post(lydata_prefix + apiUrl.getMaterialTotal, params);
}
// 获取素材库列表趋势
export function getMaterialQuota(params) {
  return axios.post(lydata_prefix + apiUrl.getMaterialQuota, params);
}
// 获取选中的素材库指标
// export function getMaterialNameList(fileName, materialId) {
//     return axios.get(apiUrl.getMaterialNameList + `?materialId=${materialId}` + `&fileName=${fileName}`);
// }
export function getMaterialNameList(data) {
  return axios.get(getUrl(apiUrl.getMaterialNameList, data));
}
// 编辑素材名称
export function updateByFileName(params) {
  return axios.post(apiUrl.updateByFileName, params);
}
// 编辑素材名称
export function getMaterialShootingId(id) {
  return axios.get(apiUrl.getMaterialShootingId + id);
}
// 获取脚本列表
export function getMakeCodeList(params) {
  return axios.post(apiUrl.getMakeCodeList, params);
}
// 获取素材审核建议
export function getMaterialReviewDetail(params) {
  return axios.post(apiUrl.getMaterialReviewDetail, params);
}
// 获取素材标签有层级
export function getMaterialLibraryLabelAnd() {
  return axios.get(apiUrl.getMaterialLibraryLabelAnd);
}
// 获取素材标签无层级
export function getMaterialLibraryLabel() {
  return axios.get(apiUrl.getMaterialLibraryLabel);
}
// 拼接get请求
function getUrl(url, params) {
  let str = '';
  Object.keys(params).forEach(key => {
    if (params[key]) {
      str += `&${key}=${params[key]}`;
    }
  });
  return url + str.replace('&', '?');
}
