<!--
 * @Author: chenxing
 * @Date: 2021-03-12 18:24:24
 * @LastEditors: LiuXin
 * @LastEditTime: 2022-01-06 11:26:43
-->
<template>
  <div class="home_wrapper w100 h100">
    <div v-if="layout?.length > 0" class="home_page w100" :key="role">
      <template v-for="c in layout" :key="c.component">
        <component :is="c.component" v-bind="c"> </component>
      </template>
    </div>
    <div v-else class="w100 h100 bcg_w padding15">首页功能正在进行中，请耐心等待。。。</div>
  </div>
</template>
<script>
import getLayout from './js/homeLayout.js';
import HomeChartWrapper from './components/HomeChartWrapper/index.vue';
import HomeTableWrapper from './components/HomeTableWrapper/index.vue';
import HomeOverViewWrapper from './components/HomeOverViewWrapper/index.vue';
import { getUserChannel } from '@/api/home/detail.js';
import { getMonthTargrtDrop } from '@/api/dashboard';

// 设计部门组长（组员）
import sjbm from './components/sjbm/index';
// 运营部门组长（组员）
import yybm from './components/yybm/index';

export default {
  name: 'HomePage',
  provide() {
    return { role: this.role };
  },
  components: { HomeChartWrapper, HomeTableWrapper, HomeOverViewWrapper, sjbm, yybm },
  data() {
    const role = this.$store.state.oauth.userInfo.jobs;
    this.$store.commit('SET_GLOBALEVENT', { args: { job: role } });
    return {
      role: role,
      grades: this.$store.state.oauth.userInfo.grades,
      layout: {},
      channel: '',
    };
  },
  watch: {
    '$store.state.globalEvent.key'() {
      if (this.$store.state.globalEvent.event === 'jobChange') {
        const job = this.$store.state.globalEvent.args.job;
        this.role = job;
      }
    },
  },
  async created() {
    await this.getUserChannel().then(() => {
      this.layout = getLayout(this.role); //通过当前人员获取在主页布局及内容
    });
    // 运营才能掉通这个接口
    if (this.role == 'OPERATION') {
      this.getOperatorDrop();
    }
  },
  methods: {
    // 获取当前人员的渠道
    async getUserChannel() {
      let res = await getUserChannel();
      this.channel = res.data;
      this.$store.commit('SET_USERCHANNEL', this.channel);
    },
    // 运营人员获取下拉数据（部门、运营人员、项目列表）
    async getOperatorDrop() {
      const urltype = ['dept', 'operator', 'project'];
      let dorpDownData = { deptList: [], operatorList: [], projectList: [] };
      for (let type of urltype) {
        try {
          const res = await getMonthTargrtDrop({ type: type });
          dorpDownData[`${type}List`] = res.data || [];
        } catch (error) {}
      }
      this.$store.commit('SET_HOMEDROPDOWNDATA', dorpDownData);
    },
  },
};
</script>
<style lang="less" scoped>
.home_wrapper {
  overflow-y: auto;
  overflow-x: hidden;
}
.home_page {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
  :deep(.home_card) {
    border-radius: 4px;
  }
  :deep(.module_title) {
    line-height: 48px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .title_content {
      font-size: 14px;
      font-weight: bold;
      margin-right: 14px;
      .anticon-down {
        margin-left: 14px;
        color: #666666;
      }
      &::before {
        content: '';
        display: inline-block;
        height: 12px;
        width: 4px;
        background-color: #2f70f4;
        margin-right: 10px;
        transform: translateY(1px);
      }
    }
    .select_group {
      @inputHeight: 30px;
      display: flex;
      align-items: center;
      height: 48px;
      padding-left: 12px;
      box-sizing: border-box;
      & > * {
        margin-right: 16px;
      }
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
      .ant-calendar-picker-input {
        height: @inputHeight;
      }
      .ant-select {
        width: 120px;
      }
      .ant-calendar-picker {
        width: 180px;
        .ant-calendar-picker-clear {
          right: 5px !important;
          margin-top: -6px !important;
        }
      }
    }
  }
}
</style>