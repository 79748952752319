/*
 * @Author: chenxing
 * @Date: 2021-04-09 17:20:16
 * @LastEditors: chenxing
 * @LastEditTime: 2021-09-03 12:03:00
 */
import OSS from 'ali-oss';
let ossConfig = {
    // accessKeyId: 'LTAI5t75D8WpgAbsopqcTDHZ',
    // accessKeySecret: 'Ryat8Y7lBukUZSXgVq49zk1rGQ6gAX',
    accessKeyId: 'LTAI5tE4KkR9LWixmLrQcmcC',
    accessKeySecret: 'sHlio58Ekx4Ze23t0MOz9v59iM4bez',
    bucket: process.env.VUE_APP_OSS_BUCKET,
};

let oss = new OSS(ossConfig);

// 支持File对象、Blob数据以及OSS Buffer。
// const data = '<File Object>';
//  const data = new Blob('content');
// or const data = new OSS.Buffer('content'));

// 普通上传
export async function upload(name, data, meta = {}) {
    try {
        // object-key可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
        let result = await oss.put(name, data, { meta: meta });
        return result;
    } catch (e) {
        console.log(e);
    }
}

// 分片上传
export async function multipartUpload(name, data, meta, progressCB) {
    try {
        name = name.replaceAll('+','_');
        let uploadOss = new OSS(ossConfig);
        // object-key可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
        let result = await uploadOss.multipartUpload(name, data, {
            progress: function (p, checkpoint) {
                progressCB && progressCB(p, checkpoint, uploadOss);
            },
            meta: { project: 'titan', ...meta },
            mime: data.type,
            timeout: 1800000
        });
        return result;
    } catch (e) {
        console.log(e);
        if (e.name == 'cancel')
            return { message: '暂停上传' };
        if (e.name == 'abort')
            return { message: '取消上传' };
        return { message: '上传失败' };
    }
}

// 恢复上传。
// let resumeOss = new OSS(ossConfig);
export async function resumeUpload(name, data, meta, progressCB, ossInstance, fileCheckPoint) {
    try {
        let result = await ossInstance.multipartUpload(name, data, {
            progress: function (p, checkpoint) {
                progressCB && progressCB(p, checkpoint, ossInstance);
            },
            checkpoint: fileCheckPoint,
            meta: { project: 'titan', ...meta },
            mime: data.type,
            timeout: 1800000
        });
        return result;
    } catch (e) {
        console.log(e);
        if (e.name == 'cancel')
            return { message: '暂停上传' };
        if (e.name == 'abort')
            return { message: '取消上传' };
        return { message: '上传失败' };
    }
}

// 暂停分片上传。
export function cancel(ossInstance) {
    ossInstance.cancel();
}

export function abortUpload(checkPoint) {
    oss.abortMultipartUpload(checkPoint.name, checkPoint.uploadId);
}

// 获取指定资源下载链接
export function getDownloadUrl(ossObjectName, saveFileName) {
    // 配置响应头实现通过URL访问时自动下载文件，并设置下载后的文件名。
    const response = {
        'content-disposition': `attachment; filename*=utf-8''${encodeURIComponent(saveFileName)}`
    };
    // object-key表示从OSS下载文件时需要指定包含文件后缀在内的完整路径，例如abc/efg/123.jpg。
    return oss.signatureUrl(ossObjectName, { response });
}

/*
* @param prefix指定只列出符合特定前缀的文件
* @param marker指定只列出文件名大于marker之后的文件
* @param delimiter用于获取文件的公共前缀
* @param max-keys用于指定最多返回的文件个数
*/
// 获取oss资源列表
export async function listDir(dir, maxkeys, subDir = true) {
    try {
        // 不带任何参数，默认最多返回1000个文件
        // let result = await oss.list();
        let config = { prefix: dir, 'max-keys': maxkeys };
        if (!subDir) {
            config.delimiter = '/';
        }
        let result = await oss.list(config);
        // 根据nextMarker继续列出文件
        let nextMarker = result.nextMarker;
        if (result.isTruncated) {
            result.objects = result.objects.concat(await (await oss.list({ marker: nextMarker })).objects);
        }

        return result.objects ?? [];
    } catch (e) {
        console.log(e);
    }
}

// 删除资源
export async function delObject(object = '') {
    try {
        let result = await oss.delete(object);
        return result;
    } catch (e) {
        console.log(e);
    }
}

// 批量删除资源
export async function delObjects(objects = [], quiet) {
    try {
        let result = await oss.deleteMulti(objects, {
            quiet: quiet
        });
        return result;
    } catch (e) {
        console.log(e);
    }
}

export async function getUrl(ossObjectName, options = {}) {
    return oss.signatureUrl(ossObjectName, options);
}