/*
 * @Author: LiuXin
 * @Date: 2021-06-04 11:37:20
 * @LastEditors: chenxing
 * @LastEditTime: 2021-11-04 12:26:41
 */
import axios from '@/utils/axios';

//查询实时消耗
export function getNowConsume(param) {
  const url = '/accounts/cost';
  return axios.get(url + `?date=${param.date}`);
}
//查询月度消耗
export function getMonthConsume(param) {
  const url = '/month/cost';
  return axios.get(url);
}
//月度目标弹框内的部门、运营、产品下拉数据(type:dept\operator\project)
export function getMonthTargrtDrop(param) {
  const url = `/month/target/${param.type}`;
  return axios.get(url);
}
//获取月度目标
export function getMonthTargrt(param) {
  const url = '/month/target';
  return axios.get(url, param);
}
//删除月度目标
export function deleteMonthTargrt(param) {
  const url = '/month/target';
  return axios.delete(url, { data: param });
}
//增加月度目标
export function addMonthTargrt(param) {
  const url = '/month/target';
  return axios.post(url, param);
}
//编辑月度目标
export function updateMonthTargrt(param) {
  const url = '/month/target';
  return axios.put(url, param);
}

//查询今日在投帐户
export function getCostingToday() {
  const url = '/product/account/_costing_today';
  return axios.get(url);
}
//首页产品消耗
export function productConsume(param) {
  const url = `/report?page=1&pageSize=10&groupBy=product_name&order=desc&sortBy=cost&startDate=${param.startDate}&endDate=${param.endDate}`;
  return axios.get(url);
}
//首页产品余额与产品消耗比
export function balanceCostRate() {
  const url = '/product/top10/balance/cost/rate';
  return axios.get(url);
}

//首页消耗趋势图
export function getConsumeTrend(param) {
  const url = `/account/report/cost?type=${param.type}&startDate=${param.startDate}&endDate=${param.endDate}`;
  return axios.get(url);
}

//首页账号、产品、运营消耗
export function getConsumeData(param) {
  const url = getUrl(`/${param.type}/report`, param);
  return axios.get(url);
}
// 拼接get请求
function getUrl(url, params) {
  let str = '';
  Object.keys(params).forEach(key => {
    if (params[key]) {
      str += `&${key}=${params[key]}`;
    }
  });
  return url + str.replace('&', '?');
}
