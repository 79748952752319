<!--
 * @Author: LiuXin
 * @Date: 2021-09-08 16:09:20
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-10-21 17:45:59
-->
<template>
  <div class="w100 h100 trend_chart">
    <!-- 模块顶部 -->
    <p class="module_title">
      <span>
        <span class="title_content">
          <span>
            {{ titleObj[filters.title] }}
          </span>
          <span>
            <a-dropdown>
              <a class="ant-dropdown-link" @click.prevent> <DownOutlined /> </a>
              <template #overlay>
                <a-menu @click="({ key }) => (filters.title = key)">
                  <a-menu-item v-for="item in $attrs.titleSelectOptions" :key="item.value">
                    <a href="javascript:;">{{ item.label }}</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </span>
        </span>
        <a class="pr15" v-if="$attrs.showToDetail" @click="gotoDetailPage">了解详情</a>
      </span>

      <span class="select_group">
        <a-select v-if="selectOptions1?.length" v-model:value="filters.value1" :options="selectOptions1" :placeholder="$attrs.placeholder1"> </a-select>
        <a-select v-if="selectOptions2?.lenght" v-model:value="filters.value2" :options="selectOptions2" :placeholder="$attrs.placeholder2"> </a-select>
        <a-range-picker v-if="$attrs.filters.dateRange" v-model:value="filters.dateRange" value-format="YYYY-MM-DD" separator="至"> </a-range-picker>
      </span>
    </p>
    <!-- chart图容器 -->
    <div class="chart_wrapper w100">
      <home-chart :chart-option="chartOption" ref="temp" v-if="filters.title != 'yestDayMake'"></home-chart>
      <div v-else class="w100 h100" style="padding: 10px">
        <a-table v-bind="table" class="w100 h100" bordered>
          <template #actorsInfo="{ record }">
            <div v-for="(item, index) in record.actorsInfo" :key="index" class="actorsInfo">
              {{ item.name }}
            </div>
          </template>
          <template #cost="{ record, index }">
            <!-- 总计 -->
            <template v-if="index == 0"> {{ record.cost }} </template>
            <!-- 详情 -->
            <template v-else>
              <div v-for="item in record.actorsInfo" :key="item" class="actorsInfo">
                {{ item.cost }}
              </div>
            </template>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import HomeChart from '../HomeChart.vue';
import * as echarts from 'echarts';
import { DownOutlined } from '@ant-design/icons-vue';
import _ from 'lodash';
import columns from '@/views/dashboard/js/tableColumn.js';
import moment from 'moment';
import { getDataByType, getConsumeData } from '@/api/dashboard/designPerson.js';
import { getConsumeTrend, productConsume, getCostingToday, balanceCostRate } from '@/api/dashboard/index.js';
import { getDepartmentData } from '@/api/org/account.js';
import { materialType } from '@/config/globalData.js';
export default {
  name: 'DesignTrendChart',
  components: { HomeChart, DownOutlined },
  data() {
    const deptId = this.$store.state.oauth.userInfo.deptId; //默认部门为当前登录人员部门
    return {
      deptId,
      chartData: [],
      filters: this.$attrs.filters,
      chartOption: _.cloneDeep(this.$attrs.echartsOptions),
      table: {
        dataSource: [],
        rowKey: 'id',
        columns: columns.yesterdayScript,
        scroll: { y: 300 },
        pagination: false,
        loading: false,
      },
      selectOptions1: this.$attrs.selectOptions1,
      selectOptions2: this.$attrs.selectOptions2,
      deptList: [],
    };
  },
  computed: {
    titleObj() {
      const obj = {};
      this.$attrs.titleSelectOptions.forEach(item => {
        obj[item.value] = item.label;
      });
      return obj;
    },
  },
  methods: {
    // 跳转详情页
    gotoDetailPage() {
      this.$router.push('/dashboard/detail');
    },
    // 设置图表数据
    async getChartData() {
      const funName = this.$attrs.getData;
      this.chartOption = _.cloneDeep(this.$attrs.echartsOptions); //重新拉取图表配置，避免污染
      this[funName](this.filters, this.chartOption, this.deptId, this.table, this);
    },
  },

  setup(props, context) {
    // 运营组员获取趋势图表数据(第二部分)
    async function operatorGetTrendData(param, option) {
      const yestoday = moment().subtract(1, 'days').format('YYYY-MM-DD');
      const dateRangeSelection = {
        近七日: [moment(yestoday).subtract(6, 'days').format('YYYY-MM-DD'), yestoday],
        近三十日: [moment(yestoday).subtract(29, 'days').format('YYYY-MM-DD'), yestoday],
        本周: [moment(yestoday).startOf('week').format('YYYY-MM-DD'), yestoday],
        本月: [moment(yestoday).startOf('month').format('YYYY-MM-DD'), yestoday],
      };
      const obj = { total: 1, product: 2, operator: 3 };
      let postData = {
        type: obj[param.title],
        startDate: dateRangeSelection[param.value1][0],
        endDate: dateRangeSelection[param.value1][1],
      };
      const res = await getConsumeTrend(postData);
      handleData(res.data, param.title, option);
      // 处理数据
      function handleData(originData, type, option) {
        let xData = [];
        let yData = [];
        let nameList = [];
        switch (type) {
          // 汇总
          case 'total': {
            const yData1 = [];
            originData.forEach(item => {
              xData.push(item.date);
              yData1.push(item.cost);
            });
            yData.push(yData1);
            break;
          }
          // top10产品
          case 'product': {
            originData.forEach(product => {
              nameList.push(product.productName);
              const yData1 = [];
              product.dateCost.forEach(item => {
                yData1.push([item.date, item.cost]);
                xData.push(item.date);
              });
              yData.push(yData1);
            });
            break;
          }
          // top10运营
          case 'operator': {
            originData.forEach(operater => {
              nameList.push(operater.operaterName);
              const yData1 = [];
              operater.dateCost.forEach(item => {
                yData1.push([item.date, item.cost]);
                xData.push(item.date);
              });
              yData.push(yData1);
            });
            break;
          }
        }
        xData = [...new Set(xData)];
        xData = xData.sort((a, b) => Date.parse(a) - Date.parse(b));
        yData.forEach((data, index) => {
          if (!option.series[index]) {
            option.series[index] = _.cloneDeep(option.series[index - 1]);
          }
          option.series[index].name = nameList[index];
          option.series[index].data = data;
        });
        option.xAxis.data = xData;
        option.legend.data = nameList;
      }
    }
    // 运营组员获取消耗图表数据(第三部分)
    async function operatorGetConsumeData(param, originOption) {
      let res = {};
      let chartData = [];
      const option = originOption[param.title];
      switch (param.title) {
        case 'compare1':
          res = await balanceCostRate();
          const balance = [];
          const cost = [];
          const productName = [];
          let arr = res.data;
          // 按余额比降序排列
          arr.sort((a, b) => a.balance - b.balance);
          arr.forEach(item => {
            balance.push(item.balance);
            cost.push(item.cost);
            productName.push(item.productName);
          });
          option.series[0].data = balance;
          option.series[1].data = cost;
          option.yAxis.data = productName;
          break;
        case 'compare2':
          res = await getCostingToday();
          const data = res.data;
          let total = 0;
          data.forEach(item => {
            total += item.count;
            chartData.push({ value: item.count, name: item.productName });
          });
          option.series[0].data = chartData;
          // this.total = total;
          break;
        case 'compare3':
          const param = {
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
          };
          res = await productConsume(param);
          res.oData.records.forEach(item => {
            chartData.push({ value: item.cost, name: item.product_name || '-' });
          });
          option.series[0].data = chartData;
          break;
      }
      this.chartOption = option;
    }

    // 设计人员获取趋势图表数据(第二部分)
    async function desinGetTrendData(param, option) {
      const postData = {
        type: param.title,
        startDate: param.dateRange?.[0],
        endDate: param.dateRange?.[1],
      };
      const res = await getDataByType(postData);
      if (!res.data) {
        return;
      }
      const originData = res.data;
      let xData = [];
      let yDataList = [];
      let legend = [];
      // 处理数据格式
      switch (param.title) {
        case 'type': //按素材内部外部
          legend = ['内部', '外部'];
          yDataList = [[], []];
          //内部
          originData.internalInfo &&
            originData.internalInfo.forEach(item => {
              yDataList[0].push([item.date, item.cost]);
              xData.push(item.date);
            });
          //外部
          originData.externalInfo &&
            originData.externalInfo.forEach(item => {
              yDataList[1].push([item.date, item.cost]);
              xData.push(item.date);
            });
          break;
        case 'make_type': //按素材类型
          const makeDict = { out: '外部', SCENARIST: '编导', FILEMAKER: '制片', PHOTOGRAPHER: '拍摄', POSTER: '后期', in: '内部', VIDEO: '视频', IMAGE: '图片', YSJJ: '剪辑',YSJJ_2X: '剪辑*2',YSJJ_3X: '剪辑*3', QJJ: '情景剧', NBZZ: '内部制作', WBCG: '外部采购', CG: '采购', KHTG: '客户提供' };

          originData.forEach((item, index) => {
            yDataList[index] = [];
            legend.push(makeDict[item.make]);
            item.info.forEach(material => {
              yDataList[index].push([material.date, material.cost]);
              xData.push(material.date);
            });
          });
          break;
        case 'material_type': //按图片or视频
          legend = ['视频', '图片'];
          yDataList = [[], []];
          //视频
          originData.VIDEO &&
            originData.VIDEO.forEach(item => {
              yDataList[0].push([item.date, item.cost]);
              xData.push(item.date);
            });
          //图片
          originData.IMAGE &&
            originData.IMAGE.forEach(item => {
              yDataList[1].push([item.date, item.cost]);
              xData.push(item.date);
            });
          break;
        case 'producer': //按设计人员
          // 计算总和
          originData.forEach((item, index) => {
            item.sum = 0;
            yDataList[index] = [];
            item.info.forEach(person => {
              item.sum += person.cost;
            });
          });
          // 对总和降序排序
          originData.sort((a, b) => b.sum - a.sum);
          // 取总和前十的
          originData.slice(0, 10).forEach((item, index) => {
            yDataList[index] = [];
            legend.push(item.designer);
            item.info.forEach(person => {
              yDataList[index].push([person.date, person.cost]);
              xData.push(person.date);
            });
          });
          break;
        case 'project': //按素材关联产品
          // 计算总和
          originData.forEach((item, index) => {
            item.sum = 0;
            yDataList[index] = [];
            item.info.forEach(project => {
              item.sum += project.cost;
            });
          });
          // 对总和降序排序
          originData.sort((a, b) => b.sum - a.sum);
          // 取总和前十的
          originData.slice(0, 10).forEach((item, index) => {
            yDataList[index] = [];
            legend.push(item.project || '--'); //因为存在没有名字的关联产品
            item.info.forEach(project => {
              yDataList[index].push([project.date, project.cost]);
              xData.push(project.date);
            });
          });
          break;
        case 'info': //素材汇总
          yDataList.push([]);
          originData &&
            originData.forEach(item => {
              yDataList[0].push([item.date, item.cost]);
              xData.push(item.date);
            });
          break;
      }
      // 将y轴数据添加到图表配置上
      yDataList.forEach((item, index) => {
        if (!option.series[index]) {
          option.series[index] = _.cloneDeep(option.series[index - 1]);
        }
        option.series[index].name = legend[index];
        option.series[index].data = item.sort((a, b) => Date.parse(a[0]) - Date.parse(b[0]));
      });
      // 将y轴数据添加到图表配置上
      xData = [...new Set(xData)].sort((a, b) => Date.parse(a) - Date.parse(b)); //对时间降重排序
      option.xAxis.data = xData;
      option.legend.data = legend;
    }
    // 设计人员获取消耗图表数据（第三部分）
    async function desinGetConsumeData(param, originOption, deptId, table, _this) {
      _this.selectOptions1 = [];
      const chartNameDict = { putState: 'compare5', typeCost: 'compare4' }; //图表名映射
      const chartName = chartNameDict[param.title];
      const option = originOption[chartName] || {};
      const yestoday = moment().subtract(1, 'days').format('YYYY-MM-DD');
      let postData = { type: param.title, endDate: yestoday };
      switch (param.title) {
        //近30天上传素材投放状态
        case 'putState': {
          postData.endDate = yestoday;
          // 这里先展示本月数据，不展示近三十天
          // postData.startDate = moment(yestoday).subtract(29, 'days').format('YYYY-MM-DD');
          postData.startDate = moment(yestoday).startOf('month').format('YYYY-MM-DD');
          const res = await getConsumeData(postData);
          const pieRadius = 25;
          const month = moment().subtract(1, 'days').format('YYYY-MM');
          option.calendar.range = [month];
          function getVirtulData() {
            let start = new Date(postData.startDate).getTime();
            let end = new Date(moment(yestoday).endOf('month').format('YYYY-MM-DD')).getTime();
            let dayTime = 3600 * 24 * 1000;
            let data = [];
            for (let time = start; time <= end; time += dayTime) {
              data.push([moment(time).format('YYYY-MM-DD')]);
            }
            return data;
          }
          function getPieSeries(chart) {
            const virtulDate = getVirtulData();
            const map = new Map();
            res.data.forEach(item => {
              map.set(item.date, item.dateCost);
            });
            const series = [];
            virtulDate.forEach((item, index) => {
              const center = chart.convertToPixel('calendar', item);
              const pieData = [];
              if (map.has(item[0])) {
                map.get(item[0]).forEach(info => {
                  pieData.push({ name: info.name, value: info.count });
                });
                series.push({
                  id: index + 'pie',
                  type: 'pie',
                  center: center,
                  label: { formatter: '{c}', position: 'inside' },
                  radius: pieRadius,
                  data: pieData,
                });
              }
            });
            return series;
          }
          const virtulDate = getVirtulData();
          const my = option;
          my.series[0].data = virtulDate;
          _this.chartOption = my;
          setTimeout(() => {
            option.tooltip.show = true;
            option.getPieSeriesByChart = getPieSeries;
            _this.chartOption = option;
          }, 1000);
          break;
        }
        //昨日素材类型及产品消耗分布
        case 'typeCost': {
          postData.startDate = yestoday;
          const res = await getConsumeData(postData);
          const osrginData = res.data;
          const inerData = []; // 内圈数据
          const outerData = []; // 外圈数据
          const legend = [[],[]]; // 图例

          osrginData.innerInfo.forEach(item => {
            inerData.push({ value: item.cost, name: materialType[item.makeType] });
            legend[0].push(materialType[item.makeType] );
          });
          osrginData.outerInfo.forEach(item => {
            outerData.push({ value: item.cost, name: item.productName });
            legend[1].push(item.productName);
          });
          option.series[0].data = inerData;
          option.series[1].data = outerData;
          option.legend[0].data = legend[0];
          option.legend[1].data = legend[1];
          break;
        }
        //昨日脚本
        case 'yestDayMake': {
          if (sessionStorage.getItem('deptList')) {
            _this.deptList = JSON.parse(sessionStorage.getItem('deptList'));
          } else {
            const res = await getDepartmentData();
            if (res.code === 0) {
              _this.deptList = res.deptList.map(item => ({ value: item.deptId, label: item.name }));
              sessionStorage.setItem('deptList', JSON.stringify(_this.deptList));
            }
          }
          _this.selectOptions1 = _this.deptList;
          const makeTypeDict = { MP: '口播', NOMP: '耗时口播', QJJNOMP: '情景剧', YSJJ: '剪辑' };
          table.loading = true;
          postData = { type: param.title, limit: 1000, page: 1, deptId: _this.filters.value1 || deptId };
          const res = await getConsumeData(postData);
          const tableData = res.data?.info || [];
          tableData.forEach(item => {
            item.makeType = makeTypeDict[item.makeType];
            item.date = moment(item.date).format('YYYY-MM-DD');
          });
          const obj = { id: new Date().getTime(), makeName: `共${res.data.totalCount || 0}条` };
          tableData.unshift(Object.assign(res.data.total, obj));
          table.dataSource = tableData;
          table.loading = false;
          break;
        }
      }
      this.chartOption = option;
    }
    return { operatorGetTrendData, operatorGetConsumeData, desinGetTrendData, desinGetConsumeData };
  },
  async mounted() {
    this.getChartData();
  },
  watch: {
    filters: {
      handler() {
        this.getChartData();
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="less">
.trend_chart {
  display: flex;
  flex-direction: column;
  .chart_wrapper {
    flex-grow: 1;
    display: flex;
    // 昨日脚本参演艺人、成本列的样式
    :deep(.ant-table-tbody) {
      .ant-table-row td {
        padding: 8px 16px;
      }
      .ant-table-row:first-child {
        td {
          border-right: 0;
          font-weight: bold;
        }
      }
      .ant-table-row:not(:first-child) {
        td:nth-child(5),
        td:nth-child(6) {
          padding: 0;
          .actorsInfo {
            text-align: center;
            padding: 4px 0;
            &:not(:last-child) {
              border-bottom: 1px solid rgb(240, 240, 240);
            }
          }
        }
      }
    }
  }
}
</style>
