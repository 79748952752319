<!--
 * @Author: LiuXin
 * @Date: 2021-09-08 15:41:46
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-09-22 15:05:14
-->
<template>
  <div class="consume_data_wrapper w100">
    <!-- chart图容器 -->
    <template v-for="c in children" :key="c">
      <component :is="c.component" v-bind="c" :department-data="departmentData" :operator-data="operatorData"></component>
    </template>
  </div>
</template>

<script>
import { getDepartmentData, getDeptUserListData } from '@/api/org/account.js';
import tableColumn from '@/views/dashboard/js/tableColumn.js';
import OperatorTable from './table/OperatorTable.vue';
import DesignerTable from './table/DesignerTable.vue';

export default {
  name: '',
  components: { OperatorTable, DesignerTable },
  props: {
    children: {
      type: Array,
      default: () => [],
    },
  },
  provide() {
    return { tableColumn };
  },
  data() {
    return {
      departmentData: [],
      operatorData: [],
    };
  },
  mounted() {
    this.getDepartment();
    this.getOperator();
  },
  methods: {
    // 获取下拉部门列表
    getDepartment() {
      getDepartmentData().then(res => {
        if (res.code === 0) {
          this.departmentData = res.deptList;
        }
      });
    },
    // 获取下拉运营
    async getOperator() {
      const param = {
        page: '1',
        pageSize: 100,
        deptId: this.$store.state.oauth.userInfo.deptId + '',
      };
      const res = await getDeptUserListData(param);
      this.operatorData = res.data?.page?.list || [];
    },
  },
};
</script>

<style scoped lang="less">
.consume_data_wrapper {
  display: flex;
  & > * {
    flex: 1;
    background-color: white;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
</style>
